import React from 'react';
import './Fun.css';
import ImageZoom from 'react-medium-image-zoom';



function Fun() {
  return (
    <div className="fun-box bigger-bg">
    <div className="processBig top-top App">
      <header className="process two-col">
        <div className="span-2">
          <h2><span className="header-serif">Personal design work</span>, experiments, slide decks, projects & high visuals
          </h2>
        </div>
        <div className="span-2">
          <p className="process-para">I try to find new ways to build my craft, from coding projects to illustrations and fine art. I want to always be growing and improving. Below, I have my DailyUI work, 36Daysoftype and some miscellaneous illustrations. </p>
        </div>
      </header>
      <div className="section-header-container">
        <div className="section-header">
          <p>Daily UI</p>
          <p>001</p>
        </div>
      </div>
      </div>
      <div className="grid-4 bigger-fun" style={{margin: 'auto'}}>
        <ImageZoom
          image={{
            src: '/assets/muex.png',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/muex.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/mu2.png',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/mu2.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/2169.png',
            className: 'img span-3',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/2169.png',
          }}
        />
        <div className="fun-width span-1" style={{ backgroundColor: 'rgb(10, 1, 30)'}}>
          <ImageZoom
            image={{
              src: '/assets/9.png',
              className: 'img',
              style: { width: '100%', marginBottom: '0px' }
            }}
            zoomImage={{
              src: '/assets/9.png',
            }}
          />

        </div>
        <div className="fun-width span-1" style={{ backgroundColor: 'rgb(32, 34, 45)' }}>
          <ImageZoom
            image={{
              src: '/assets/blogpic.png',
              className: 'img',
              style: { width: '100%' }
            }}
            zoomImage={{
              src: '/assets/blogpic.png',
            }}
          />
        </div>
        <ImageZoom
          image={{
            src: '/assets/2171.png',
            className: 'img span-3',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/2171.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/daily009.gif',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/daily009.gif',
          }}
        />
        {/* <ImageZoom
          image={{
            src: '/assets/2171.png',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/2171.png',
          }}
        /> */}
        <ImageZoom
          image={{
            src: '/assets/daily008.png',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/daily008.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/c.png',
            className: 'img span-3',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/c.png',
          }}
        />

      <div className="fun-width" style={{ backgroundColor: '#35356c' }}>

        <ImageZoom
          image={{
            src: '/assets/daily007.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/daily007.png',
          }}
        />
        </div>

        <ImageZoom
          image={{
            src: '/assets/layer.png',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/layer.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/moment2.png',
            className: 'img span-2',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/moment2.png',
          }}
        />
        <div className="fun-width" style={{ backgroundColor: '#f0f4fb' }}>

        <ImageZoom
          image={{
            src: '/assets/005.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/005.png',
          }}
        />
        </div>
        <ImageZoom
          image={{
            src: '/assets/DribbbleContest.gif',
            className: 'img span-3',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/DribbbleContest.gif',
          }}
        />



        <ImageZoom
          image={{
            src: '/assets/day3.gif',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/day3.gif',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/002.gif',
            className: 'img span-2',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/002.gif',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/001.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/001.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/daily010.gif',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/daily010.gif',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/8.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/8.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/7.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/7.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/6.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/6.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/5.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/5.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/4.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/4.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/3.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/3.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/2.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/2.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/1.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/1.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/0.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/0.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/z.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/z.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/y.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/y.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/x.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/x.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/w.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/w.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/v.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/v.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/u.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/u.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/t.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/t.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/s.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/s.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/r.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/r.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/calc.gif',
            className: 'img span-3',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/calc.gif',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/q.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/q.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/p.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/p.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/o.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/o.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/n.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/n.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/m.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/m.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/l.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/l.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/k.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/k.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/j.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/j.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/i.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/i.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/h.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/h.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/g.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/g.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/f.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/f.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/covidcon.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/covidcon.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/e.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/e.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/d.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/d.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/b.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/b.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/a.png',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/a.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/pears.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/pears.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/lemons.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/lemons.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/draw1.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/draw1.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/draw2.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/draw2.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/draw3.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/draw3.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/toronto.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/toronto.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/pur.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/pur.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/hello.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/hello.png',
          }}
        />

        <ImageZoom
          image={{
            src: '/assets/bahen.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/bahen.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/rano.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/ranobig.png',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/dailyui006.gif',
            className: 'img',
            style: { width: '100%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/dailyui006.gif',
          }}
        />
        <ImageZoom
          image={{
            src: '/assets/fireworks.png',
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/fireworks.png',
          }}
        />
        </div>
       </div>

  );
}

export default Fun;
