import React from 'react';
import CaseInfo from './CaseInfo';
import './Wish.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
import Button from '../Button';

function Wish() {

  return (
    <div className="App project-study">
      <CaseHeader
        image="/assets/Home.png"
        class="wish"
      />
      <div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                title="Wish"
                desc="My experience being a product design intern during the winter of 2021"
                overview="During the Winter of 2021, I had the chance to be a Product design intern for the Marketplace team at Wish. Although I was hired to work on the merchant side, I had the chance to work the core consumer app and on the Wish design system. I had an absolute blast and learned so much from it. I wanted to share my experience and what I learned as a result."
                role="Product Design Intern"
                timeline="Jan 2021 - Apr 2021"
                class="wish"
              />
            </div>
            <div className="project-study-inner">
            <div className="bigger-bg">
                <div id="projects" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Exploring Wish</p>
                    <p>003</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title">
                  <div className="bsc-2-grid case-spacer">
                        <h2><span className="header-serif">Projects</span> I got <br/>to work on</h2>
                    <p className="project-study-inner">
                      I had the chance to work on 10 different projects. These projects ranged from small illustration projects or landing pages to bigger projects that impacted the entire design org or merchants at Wish. The following projects were my favourite and I had the most fun with them.
                    </p>
                  </div>

                  </div>
                  <div className="info" style={{marginBottom: "32px"}}>
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Design System: Colors</h4>
                        <p className="project-study-inner-small" style={{marginBottom: '32px', marginTop: '8px'}}>It I owned the design system's color tokens from conception, auditing, iteration to delivery.
                        <div style={{ marginTop: '12px' }}>
                         <Button  href="https://bootcamp.uxdesign.cc/a-wish-design-system-color-story-ef2c38de97e" target="_blank">View Case Study</Button>
                            </div>
                        
                        </p>
                       
                      </div>
                      <div className="span-1">
                          <h6 className="top-number">02</h6>
                        <h4>Product Compliance</h4>
                        <p className="project-study-inner-small">I scoped, designed & iterated over ensuring compliance for Wish merchants</p>
                        <h4>Coming soon</h4>
                      </div>
                      
                    </div>

                  </div>

                </div>


              </div>
            </div>
              <div id="skill" className="mid-width case-study-body case-study-body-section">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>What I Learned</p>
                    <p>004</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="title">
                  <div className="bsc-2-grid">
                    <h2>How my skills <br/>have <span className="header-serif">improved</span></h2>
                    <p className="project-study-inner">I had the chance to tackle really interesting problems that tested multiple skills. By far, the skill I improved the most was my cross-team collaboration and communication. Not only did I get to work with PMs, designers and engineers, I also interfaced with stakeholders such as lawyers, merchant logistics teams and writers.</p>
                    </div>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/wish-1.svg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/wish-1.svg"
                  }}
                />




              </div>
              <div id="fun" className="mid-width case-study-body case-study-body-section">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Bonding & Hanging Out</p>
                    <p>005</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>The Fun Stuff</h2>
                    <p className="project-study-inner">Not only did we work hard, we also played hard. We had a boba making session where we made tapioca pearls from scratch and milk tea. We critiqued art together, played LOTS of skribbl.io (My manager, Simon, is STILL the undefeated champion). I formed close bonds with the people and built relationships of full of trust, fun laughter.
                    </p>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/boba.png",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/boba.png"
                  }}
                />
                <ImageZoom
                  image={{
                    src: "/assets/game.png",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/game.png"
                  }}
                />




              </div>
              <div className="bigger-bg">

              <div id="learn" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Reflect</p>
                    <p>006</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title bsc-2-grid">
                      <h2><span className="header-serif">Reflecting</span> on the project outcome</h2>
                    <p className="project-study-inner">I had the chance to tackle really interesting problems that tested multiple skills. By far, the skill I improved the most was my cross-team collaboration and communication. Not only did I get to work with PMs, designers and engineers, I also interfaced with stakeholders such as lawyers, merchant logistics teams and writers.</p>
                  </div>
                  <div className="bsc-2-grid">
                  <div className="span-1">
                    <h6 className="top-number">01</h6>
                    <h4>Document Everything</h4>
                      <p className="project-study-inner-small">It is so important to always leave information behind. While working on projects we would run into weird edge cases where we did not understand why it was built that way and no one on the team knew either. I learned that I had to leave detailed notes behind.</p>
                  </div>
                  <div className="span-1">
                        <h6 className="top-number">02</h6>
                    <h4>Overcommunicate</h4>
                      <p className="project-study-inner-small">This lesson ties in with documentation. As designers, communication is everything. I spent time updating jira tickets, in meetings where demoed my ideas, in consensus meetings and 1 on 1s where I expressed everything I was dealing with. Don't design in a silo. People can't help you if they don't know you're stuck.</p>
                  </div>
                  <div className="span-1">
                        <h6 className="top-number">03</h6>
                    <h4>Getting buy-ins is tough</h4>
                      <p className="project-study-inner-small"> learned the hard way how tough it can be to sell your ideas and defend them. Not only did this apply for interaction or design decisions, but also process decisions. Do we need to do research now? Can we make time for research or a brainstorming session? How can I convince my PM that this would be a value add?</p>
                  </div>
                  <div className="span-1">
                        <h6 className="top-number">04</h6>
                    <h4>Build deep relationships</h4>
                      <p className="project-study-inner-small">During the internship, it was super fun meeting everyone and getting to learn from different perspectives. I quickly learned that I wanted to build deeper relationships and connections so I focused on a handful of people on my team that I could get to know and rely on.</p>
                  </div>
                  </div>

                </div>



              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Wish;
