import React from 'react';
import Button from '../Button';
import './CaseInfo.css';

function CaseInfo(props) {
  let overview = null;
  if (props.problem) {
    overview = (
      <div>
        <div className="section-header-container about">
          <div className="section-header">
            <p>Overview</p>
            <p>002</p>
          </div>
        </div>
        <div className="case-study-problem">
          <div>
            <h4>Problem</h4>
            <p className="process-main-text">{props.problem}</p>
          </div>
          <div>
            <h4>Outcome</h4>
            <p className="process-main-text">{props.outcome}</p>
          </div>
        </div>
      </div>
    );
  }

  let link;

  if (props.link) {
    link = (
      <div style={{marginTop: 24}}>
      <Button href={props.link}>{props.linkname}</Button>
      </div>
    );

  }

    let team;
    if (props.team) {
      team = (
        <div>
          <h4>Team</h4>
          {props.team.map(person => {
              return(
                <p className="case-info-right-text">{person}</p>
              )
            })}
        </div>
      )
  }

    let tools;
    if (props.tools) {
      tools = (
        <div>
          <h4>Tools</h4>
          {props.tools.map(tool => {
              return(
                <p className="case-info-right-text">{tool}</p>
              )
            })}
        </div>
      )
  }
    let sector;
    if (props.sector) {
      tools = (
        <div>
          <h4>Sector</h4>
          <p className="case-info-right-text">{props.sector}</p>
        </div>
      )
  }
  return (
    <div>
      <div className="case-study-header">
        <div className="case-info">
          <div className="case-study-info left-info">
            <div className="section-header-container about">
              <div className="section-header">
                <p>Project</p>
                <p>001</p>
              </div>
            </div>
            <h1>{props.title}</h1>
            <h2>{props.desc}</h2>
            <h3>{props.overview}</h3>
            {overview}
            
          </div>
          <div className="case-study-info right-info">
            <div className="section-header-container about">
              <div className="section-header">
                <p>Info</p>
                <p>{props.problem ? '003' : '002'}</p>
              </div>
            </div>
            <div className="case-study-info-right-group">
              <div>
                <h4>Role</h4>
                <p className="case-info-right-text">{props.role}</p>
              </div>
              <div>
                <h4>Timeline</h4>
                <p className="case-info-right-text">{props.timeline}</p>
              </div>
              {tools}
              {team}
              {sector}
              {link}
            </div>
          </div>
        </div>
      </div>
         
    </div>
  );
}

export default CaseInfo;
