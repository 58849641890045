import React from 'react';
import './NotFound.css';


function NotFound(props) {
  let image = '/assets/404-light.svg';
  if (localStorage.getItem('darkTheme') === 'true') {
    image = '/assets/404-dark.svg';
  }
  
  return (
    <div className="bg-404">
      <img src={image} className="bg-image-404" alt="404 with flowers and trees around it"/>
      <h3>Sorry! The page was not found. <br/> I am in the middle of redesigning and coding my portfolio so a few pages have yet to be added! If you're looking for a project I've had before, check back later, as I code them back in. :)</h3>
    </div>
  );
}

export default NotFound;
