import React from 'react';
import './CaseHeader.css';

function CaseHeader(props) {
  return (
    <div>
      <header className="case-study-header">
        <div className={"case-study-image " + props.class}>
          <img src={props.image} className="case-header-image" alt="Case Study Hi-Fi"/>
        </div>
      </header>
         
    </div>
  );
}

export default CaseHeader;
