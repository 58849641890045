import './App.css';
import TextLoop from "react-text-loop";

const projects = [

  {
    id: 'rom',
    title: 'Museum AR/VR',
    description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
    image: './assets/rommain.png',
    class: 'rom light-in-dark',
    link: '/projects/rom',
    dates: 'Dec 2019',
    type: 'Case Study • Product & Brand Design • Personal Project'
  },
  {
    id: 'menti',
    title: 'Menti',
    description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
    image: './assets/men-dash.png',
    class: 'menti light-in-dark',
    link: '/projects/menti',
    dates: 'Dec 2019',
    type: 'Overview • School • Coded Prototype'
  },
  {
    id: 'meta',
    title: 'Meta',
    description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
    image: './assets/meta-home.svg',
    class: 'meta light-in-dark',
    link: '/projects/meta',
    dates: 'Dec 2019',
    type: 'Overview • Internship • Product Design'
  },
  {
    id: 'mi-sched',
    logoDark: './assets/wish-logo.svg',
    logoLight: './assets/wish-light.svg',
    title: 'MI Scheduler',
    description: 'An overview of projects and a casual look at my experience as a product design intern at Wish',
    image: './assets/mimain.svg',
    class: 'mi-sched',
    link: '/projects/mi',
    dates: 'Jan 2021 - Apr 2021',
    type: 'Product Design • Personal Project • Shipped'
  },
  {
    id: 'finary',
    title: 'Finary 🔒',
    description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
    image: './assets/finhome.jpg',
    class: 'finary light-in-dark',
    link: '/projects/finary',
    dates: 'Dec 2019',
    type: 'NFT Gallery • Product Design • Capstone'
  },

  {
    id: 'movingchar',
    title: 'Blueprint',
    description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
    image: './assets/blueprint.jpg',
    class: 'movingchar light-in-dark',
    link: '/projects/blueprint',
    dates: 'Dec 2019',
    type: 'Product Design • Branding • Overview • Volunteer'
  },
  // {
  //   id: 'align',
  //   logoDark: './assets/blueprint.svg',
  //   title: 'Align',
  //   description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
  //   image: './assets/Homealign.jpg',
  //   class: 'align light-in-dark',
  //   link: '/projects/align',
  //   dates: 'Oct - Dec 2021',
  //   type: 'Product Design • Case Study • Class Project'
  // },
  {
    id: 'bsc',
    title: 'Boston Scientific 🔒',
    description: 'A brand new design system for the suite of Boston Scientific products. This project is password protected.',
    image: './assets/bsc-top.png',
    class: 'bsc',
    link: '/projects/bsc',
    dates: 'Sep 2020 - Dec 2020',
    type: 'Design System • Contract • Case Study'

  },
  {
    id: 'potluck',
    logoDark: './assets/potluck-dark.svg',
    logoLight: './assets/potluck-light.svg',
    title: 'Potluck',
    description: 'A simpler product management tool. I improved the usability from 40% to 96%.',
    image: './assets/potluck-top.png',
    class: 'potluck',
    link: '/projects/potluck',
    dates: 'Jan 2021 - April 2021',
    type: 'Product Design • Case Study • Freelance'
  },
  {
    id: 'wish-main',
    logoDark: './assets/wish-logo.svg',
    logoLight: './assets/wish-light.svg',
    title: 'Wish',
    description: 'An overview of projects and a casual look at my experience as a product design intern at Wish',
    image: './assets/Home.png',
    class: 'wish',
    link: '/projects/wish',
    dates: 'Jan 2021 - Apr 2021',
    type: 'Product Design • Case Study • Internship'
  },
  // {
  //   id: 'instura',
  //   logoDark: './assets/parsehub-dark.svg',
  //   logoLight: './assets/parsehub-light.svg',
  //   title: 'Instura',
  //   description: 'A mobile app and web extension that helps makeup lovers combat consumerism',
  //   image: './assets/ins.png',
  //   class: 'instura light-in-dark',
  //   link: '/projects/instura',
  //   dates: 'Dec 2019',
  //   type: 'Product Design • Case Study • Personal Project'

  // },
  // {
  //   id: 'moment',
  //   logoDark: './assets/parsehub-dark.svg',
  //   logoLight: './assets/parsehub-light.svg',
  //   title: 'Moment',
  //   description: 'An overview of projects and a casual look at my experience being a product design intern during the winter of 2021 at parsehub.',
  //   image: './assets/momentintro.png',
  //   class: 'moment',
  //   link: '/projects/moment',
  //   dates: 'Jan 2021 - April 2021',
  //   type: 'Product Design • Case Study • Full Time'

  // },

  {
    id: 'wish-dc',
    logoDark: './assets/wish-logo.svg',
    logoLight: './assets/wish-light.svg',
    title: 'Wish Design Exercise',
    description: 'A design challenge for improving engagement on the e-commerce mobile app Wish.',
    image: './assets/wishdc-top.png',
    class: 'wish-dc',
    link: '/projects/wish-design-challenge',
    dates: 'Jan 2021 - April 2021',
    type: 'Product Design • Design Challenge'

  },
  // {
  //   id: 'parsehub',
  //   logoDark: './assets/parsehub-dark.svg',
  //   logoLight: './assets/parsehub-light.svg',
  //   title: 'Parsehub',
  //   description: 'An overview of projects and a casual look at my experience being a product design intern during the winter of 2021 at parsehub.',
  //   image: './assets/parsehub.png',
  //   class: 'parsehub',
  //   link: '/projects/parsehub',
  //   dates: 'Jan 2021 - April 2021',
  //   type: 'Product Design • Full Time'

  // },


]

function Home() {

  const renderCard = () => {
    return projects.map(item => {
      let lightMode;
      if (localStorage.getItem('darkTheme') === 'false') {
        lightMode = 'dark-in-light'
      }
      console.log(item);
      

      if (item.id === "rom") {
        return(
          <a href={item.link} className={lightMode + " home home-project " + item.class}>
            <div className={"homes info " + item.class}>
              <div>
                <h2 className={"homes internships " + item.class}>{item.title}</h2>
                <p className={"homes para " + item.class}>{item.type}</p>

              </div>
            </div>
            <div className="rom-vid">
              <video autoPlay muted width="100%" className="span-3" loop>
                <source src="/assets/romHome.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </a>
        )

      } else {
        return (
          <a href={item.link} className={lightMode + " home home-project " + item.class}>
            <div className={"homes info " + item.class}>
              <div>
                <h2 className={"homes internships " + item.class}>{item.title}</h2>
                <p className={"homes para " + item.class}>{item.type}</p>

              </div>
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <img className={"homes image-inner " + item.class} src={item.image} alt="" />
            </div>
          </a>
        )

      }

    });


  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-about">
          <h1><span className="header-color-span"><span className="header-weight-span">Hello</span>! I'm Tammy,</span><br/>
          <span className="textloop-cont">
          <span className="textloop">
            <TextLoop noWrap={false} mask={true} interval={2000}>
                <span>I could eat my weight in Korean short ribs</span>
                <span>I value empathy & vulnerability</span>
                <span>I studied stats, CS & neuroscience</span>
                <span>I have been to 14 countries</span>
                <span>I started designing at the age of 10</span>
                <span>Minecraft is the best game ever created</span>
                <span>Seriously, I really love playing Minecraft</span>
                <span>Bubble tea is my favourite food group</span>
                <span>I write medium articles about design</span>


            </TextLoop>
            </span>
            </span>
          </h1>
          
        </div>
        <div className="header-welcome">
          <h5>Welcome to my portfolio</h5>
          <p>I am a product designer & front-end engineer with some illustration chops. I have a wide range of experience from big tech and agency to and startups. Co-Founder @ Octoshop. Incoming @ Figma. Previously @ <span className="header-serif">Meta, Xbox & Wish</span></p>
          <h5 style={{paddingTop: '48px'}}>It’s nice to meet you, check out my work below</h5>
        </div>
      </header>
      <div>
      <div className="section-header-container" style={{padding: "0 20px"}}>
        <div className="section-header">
          <p>Work</p>
          <p>001</p>
        </div>
      </div>

      <div className="home projects">
        {renderCard()}
      </div>
      </div>
    </div>
  );
}

export default Home;
