import React from 'react';
import CaseInfo from './CaseInfo';
import './Menti.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
// import Nav from '../Nav';
import Button from '../Button';


function Menti() {
  return (
    <div className="App project-study menti-case">
      <CaseHeader
        image="/assets/men-dash.png"
        class="menti"
      />
      <div>
        <div className="case-study-navi" id="nav">
        </div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/menti-home.svg"
                title="Menti"
                desc={<span>Platform for designers to practice for <span className="header-serif">design interviews</span> and gain mentorship</span>}
                overview={<span>For a class during my time in grad school, we were tasked with creating a platform that filled an unmet need. My partner, Amanda, and I decided to explore design interview preparation as a domain. We designed coded and ran a very quick usability test within 3 weeks. Given then short turn around, the <b> goal for this project was to build a solution and not to make the most effective solution.</b></span>}
                role="Product Designer & Developer"
                timeline="3 weeks"
                problem="How might support designers who want to practice whiteboarding, app critques and portfolio presentations?"
                outcome={<div><span style={{display: 'block', marginBottom: '12px'}}>We designed and coded a prototype for supporting designers with design interviews in 2 weeks. You can view the site here:</span>

                  <Button target="_blank" style={{display: 'block' }}href="https://menti.netlify.com">Prototype</Button>

                </div>}
                tools={['Figma', 'ReactJS',]}
                team={['Amanda Sim - Designer']}
                class="menti"
              />
            </div>

            <div className='menti-intro-cont'>
              <img src="/assets/menti-bg.jpg" className='menti-intro-img' alt=""/>

              <video autoPlay loop muted className='mento-video'>
                <source src="/assets/menti-vid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="bigger-bg" id="Mentor">
              <div className="mid-width case-study-body">
  
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Design Brief</p>
                    <p>004</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title bsc-2-grid">
                    <h2>From conception to <span className="header-serif"> design & coded prototype</span> in 3 weeks</h2>
                    <p className="project-study-inner">I'm proud of this project because we were able to design and code a static prototype all within two weeks. While we did conduct usability test, the goal was to build something. Here is how the work was broken down. </p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Week 1: Research & Design</h4>
                        <p className="project-study-inner-small">Amanda and I ran a survey to quickly learn what users were currently experiencing. We had 15 responses. I designed the first few set of screens to define the visual style and information architecture. </p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">02</h6>

                        <h4>Week 2: Code & Design</h4>
                      <p className="project-study-inner-small">Once the overall basic designs were set, I coded the whole thing using ReactJS and Bootstrap as a base for the UI library. Amanda took over and designed the rest and we collaborated on changes as needed before I coded the rest.</p>
                      </div>
                    <div className="span-1">
                      <h6 className="top-number">03</h6>

                      <h4>Week 3: Usability test & paper</h4>
                      <p className="project-study-inner-small">Amanda and I ran 8 usability tests to test if the product was usable and useful and wrote up our paper for the project.</p>
                    </div>
                    </div>
                  </div>
                </div>
            </div>
</div>
            <div id="process" className="mid-width case-study-body case-study-body-section section-spacer">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>User Research</p>
                  <p>005</p>
                </div>
              </div>

              <div style={{ marginTop: '24px' }}>
                <div className="title bsc-2-grid">
                  <h2>User research<span className="header-serif"> results & findings</span></h2>
                  <p className="project-study-inner">Given the quick turn around, we conducted a small survey study with 15 people. Most of these individuals are new/junior designers. Here are the results:</p>

                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">87%</h6>
                      <h4>of the survey takers ask for help from friends but the advice isn't always great</h4>
                      <p className="project-study-inner-small">When practicing interviews, junior designers go to their friends to practice with. They feel that friends are more easy going and approaching them is easier. However, friends don't always provide the best advice. </p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">85%</h6>

                      <h4>of survey takers believe that they'd trust designers based on their years of experience </h4>
                      <p className="project-study-inner-small">Followed closely by "senior" rank and if the designer works for a FAANG company. Some survey takers trust help from others only if they work for the specific company they are applying for.</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="bigger-bg dark-section-smaller" id="Mentor">
              <div className="mid-width case-study-body">
                <div className="section-header-container about no-bottom">
                  <div className="section-header">
                    <p>Feature #1</p>
                    <p>006</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="title">
                    <div className="bsc-2-grid">
                      <h2>Mentorship as a <span className="header-serif">default concept</span> </h2>
                      <p className="project-study-inner">The best place to get interview practice is from senior designers. We added a mentorship feature as a default experience. Users sign up for interview practice sessions with more experienced designers.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bsc-2-grid">
                <div>
                  <ImageZoom
                    image={{
                      src: "/assets/men-men-1.jpg",
                      className: "img",
                      style: { width: "100%" }
                    }}
                    zoomImage={{
                      src: "/assets/men-men-1.jpg"
                    }}
                  />
                </div>
                <div>
                  <ImageZoom
                    image={{
                      src: "/assets/men-men-2.jpg",
                      className: "img",
                      style: { width: "100%" }
                    }}
                    zoomImage={{
                      src: "/assets/men-men-2.jpg"
                    }}
                  />
                </div>
              </div>

            </div>
 


            <div id="screen" className="mid-width case-study-body case-study-body-section section-spacer no-bottom dark-section-smaller ">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Feature #2</p>
                  <p>007</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Phone screen sharing for <span className="header-serif"> app critiques</span></h2>
                <p className="project-study-inner">App critiques for mobile apps is difficult because sharing phone screen can be complicated. We added the ability to easily share phone screen by scanning a QR code.</p>
              </div>
            </div>
            <div className="potluck-3-grid">
              <div style={{ backgroundColor: "#B3BBBF" }}>
                <ImageZoom
                  image={{
                    src: "/assets/men-screen-1.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/men-screen-1.jpg"
                  }}
                />
              </div>
              <div className="span-2">
                <ImageZoom
                  image={{
                    src: "/assets/men-screen-2.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/men-screen-2.jpg"
                  }}
                />
              </div>
            </div>
            <div className="bigger-bg dark-section-smaller" id="info">
              <div  className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Feature #3</p>
                    <p>008</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Company insider hiring <span className="header-serif">secrets & tips</span></h2>
                  <p className="project-study-inner">Another difficult part of interviewing is not knowing what companies look for in designers. We added a section of the site dedicated to company guides for interviewing so users can see exactly what interviewers are deciding on. </p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <div>
                  <ImageZoom
                    image={{
                      src: "/assets/men-info-1.jpg",
                      className: "img",
                      style: { width: "100%" }
                    }}
                    zoomImage={{
                      src: "/assets/men-info-1.jpg"
                    }}
                  />
                </div>
                <div>
                  <ImageZoom
                    image={{
                      src: "/assets/men-info-2.jpg",
                      className: "img",
                      style: { width: "100%" }
                    }}
                    zoomImage={{
                      src: "/assets/men-info-2.jpg"
                    }}
                  />
                </div>
              </div>
              </div>

            <div id="stats" className="mid-width case-study-body case-study-body-section section-spacer no-bottom">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Feature #4</p>
                  <p>009</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Whiteboard video calls <span className="header-serif">& prompt generator</span></h2>
                <p className="project-study-inner">Practicing whiteboarding is another major challenge, first to find a prompt and then join a call and share a whiteboarding screen. We added a whiteboard for video calls with a prompt generator so everything they need is in one place.</p>
              </div>



            </div>

            <div className="potluck-3-grid">
              <div style={{ backgroundColor: "#B3BBBF" }}>
                <ImageZoom
                  image={{
                    src: "/assets/men-whiteboarding-1.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/men-whiteboarding-1.jpg"
                  }}
                />
              </div>
              <div className="span-2 whiteboarding-img" style={{ backgroundImage: "url('/assets/men-whiteboarding-2.jpg')",  }}>
                <video autoPlay loop muted width="80%" style={{  borderRadius: '12px' }}>
                  <source src="/assets/whiteboard.mov" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

              </div>


            </div>

            

            <div id="team" className="bigger-bg">
              <div className="mid-width case-study-body">

                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Code</p>
                    <p>010</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Coding a <span className="header-serif"> prototype</span> from scratch</h2>
                  <p className="project-study-inner">Coding this was my favourite part. I used ReactJS and Bootstrap for the base design system. With no backend, a lot of the data was mocked-up in JSON format to mimic a backend.</p>
                </div>

                <ImageZoom
                  image={{
                    src: '/assets/men-code.jpg',
                    className: 'img',
                    style: { width: '100%', marginTop: 50, borderRadius: '12px' }
                  }}
                  zoomImage={{
                    className:'hello',
                    src: '/assets/men-code.jpg',
                  }}
                />

            </div>
            </div>



            <div id="fun" className="mid-width case-study-body case-study-body-section section-spacer no-bottom">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>The End</p>
                  <p>011</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>The full design <span className="header-serif">& coded prototype</span></h2>
                <p className="project-study-inner">With that, here is the prototype hosted on netlify. We alo did 8 usability tests with this prototype to test the efficacy of the designs
                </p>

                <Button style={{ display: 'block' }} target="_blank" href="https://menti.netlify.com">View Prototype</Button>
              </div>

            </div>
            <div className='menti-intro-cont-last' style={{backgroundImage: 'url("/assets/night.jpeg")'}}>
              <video autoPlay loop muted className='mento-video-last'>
                <source src="/assets/menti-vid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div id="reflection" className="bigger-bg">
              <div  className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Lessons Learned</p>
                    <p>012</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                  <p className="project-study-inner">This was a quick project, lasting only 3 weeks from start to submission. Here are my reflections:</p>
                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">01</h6>
                      <h4>Giving good feedback</h4>
                      <p className="project-study-inner-small">It's not enough to just say a design is wrong. Describing why and what could be done potentially is substantially more helpful because if provides direction. It's also important to give others autonomy to decide if they want to take your suggestion.</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">02</h6>
                      <h4>We did that!</h4>
                      <p className="project-study-inner-small">I'm really proud of our ability to do research, design, code and run usability tests all within 3 weeks. We did all of this while being students and while I was working full time.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            


        </div></div>
      </div>
    </div>
  );
}

export default Menti;
