import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Header.css'
import { withRouter } from "react-router";

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let active;
    let location = this.props.history.location.pathname.split('/')[1];

    if (location.includes("process")) {
      active = "process";
    } else if (location.includes("freelance")) {
      active = 'freelance';
    } else if (location.includes("fun") || location.includes("36daysoftype") || location.includes("misc") || location.includes("uichall")) {
      active = 'ill';
    } else if (location.includes("about")) {
      active = 'about';
    } else if (location.includes("articles")) {
      active = 'pub';
    } else if (location.includes("work") || location.includes("project") || location.includes("")) {
      active = "work"
    }

    return (
      <Navbar collapseOnSelect expand="lg">
          <span className="container">
          <div className="hidden-brand">
            <Navbar.Brand href="/">
              <span className="brand">
                <img className="brand_logo" height="52px" alt="logo" src={'/assets/logo-dark.svg'} />

              </span>
            </Navbar.Brand>
          </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link href="/" className={active === "work" ? "active" : null}>Work</Nav.Link>
                <Nav.Link href="/process" className={active === "process" ? "active" : null}>My Design Process</Nav.Link>
                <Nav.Link href="/freelance" className={active === "freelance" ? "active" : null}>Freelance</Nav.Link>

              <Navbar.Brand href="/">
                <span className="brand">
                  <img className="brand_logo" height="52px" alt="logo" src={'/assets/logo-dark.svg'} />

                </span>
              </Navbar.Brand>
                <Nav.Link href="/fun" className={active === "ill" ? "active" : null}>Fun</Nav.Link>
                <Nav.Link href="/articles" className={active === "pub" ? "active" : null}>Articles</Nav.Link>
                <Nav.Link href="/about" className={active === "about" ? "active" : null}>About</Nav.Link>
                <Nav.Link href="/Tammy_Taabassum_resume.pdf" target="_blank" style={{paddingRight: '-16px'}}>Resume</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </span>
        </Navbar>
    );
  }
}

export default withRouter(Header)