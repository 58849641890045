import React from 'react';
import CaseInfo from './CaseInfo';
import './Finary.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
import Password from "./Password";

function Finary() {

  const cat = localStorage.getItem('tamPasswordSet');
  if (!cat) {
    return (
      <Password />
    )
    
  }

  return (
    <div className="App project-study">

      <CaseHeader
        image="/assets/finhome.jpg"
        class="finary"
      />
      <div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/finhome.jpg"
                title="Finary"
                desc={<span>Discord meets Robinhood. Adding <span className="header-serif"> NFT gallery & profile picture support</span>  to a fin-tech platform  </span>}
                overview="For my Master's Capstone project, my team and I worked with the design agency ElectroPath. More specifically, we worked on a project for their their client, Finary. Our goal centered around Finary being the best place to display and share NFTs online, no matter what platform they were purchased on, within our scope. It must be secure and safe, with a focus on conspicuous trust."
                problem="Many Finary users have NFTs that they want to share, show off, and eventually trade. If Finary is to become the place to discuss crypto, it must integrate NFTs in a robust, decentralized way."
                outcome="We delivered an as-is and to-be journey map, research and a clickable prototype using the Finary Design System."
                role="Product Designer"
                timeline="Jan 2022 - April 2022"
                tools={['Figma']}
                class="bsc"
                team={['Huimin Yvonne Zhan', 'Humphrey Zhang', 'Nina Brkanovic', 'Sara Fontes','Youjing Li']}
              />
              </div>
            <div className="grid-4" style={{marginTop: '120px'}}>
              <video autoPlay loop muted width="100%" className="span-3">
                <source src="/assets/finary.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className='finary-people' style={{ backgroundImage: "url('/assets/finpeoplepng.jpeg')" }}>
              </div>

            </div>
            <div className="project-study-inner section-spacer">
              <div id="process" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Design Brief</p>
                    <p>004</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title bsc-2-grid">
                    <h2>How can we support <span className="header-serif"> NFT sharing</span> on Finary?</h2>
                    <p className="project-study-inner">Allow users to display their NFT collections on their profiles and give them cool ways to show them off ( e.g. NFT profile pictures). What creative ways can we give people a sense of community, joy and sharing through their NFT collection?</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>NFT Curation</h4>
                        <p className="project-study-inner-small">How do NFTs look when people view someone’s collection on their profile?</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        
                        <h4>NFT Profile Picture</h4>
                        <p className="project-study-inner-small">How do people show off NFTs in specialized NFT profile pictures?</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              </div>
              <div>
              <div className="bigger-bg">
              <div id="audit" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Research</p>
                    <p>005</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                      <h2><span className="header-serif">Conducting</span> research on existing experiences</h2>
                    <p className="project-study-inner">We conducted different forms of research to understand this problem. The research provided us with a deeper understanding of the NFT sharing, curation and collection experience.</p>
                  
                  
                  </div>
                    <div className="info">
                      <div className="bsc-2-grid">
                        <div className="span-1">
                          <h6 className="top-number">01</h6>
                          <h4>Observation Study</h4>
                          <p className="project-study-inner-small">We joined Finary and observed the current NFT sharing experience. We looked at how NFTs were shared and the types of conversations that people were having about NFTs. This helped us understand what users on Finary were trying to accomplish.</p>
                        </div>
                        <div className="span-1">
                          <h6 className="top-number">02</h6>

                          <h4>Competitor Analysis</h4>
                          <p className="project-study-inner-small">We then completed a competitor analysis with 7 competitors. This included Meta Mask, Meta Link, OpenSea, Super Rare, Rainbow, Coinbase,and BubbleHouse. We looked to see how they enabled NFT sharing and collection curation.</p>
                        </div>
                        <div className="span-1">
                          <h6 className="top-number">03</h6>

                          <h4>9 Semi-Structured Interviews</h4>
                          <p className="project-study-inner-small">We conducted 9 semi-structured Interviews. 2 individuals were Finary users. We interviewed NFT veterns and those new to the NFT world. Our goal was to understand NFT sharing experiences, methods, benefits and dangers.</p>
                        </div>
                        <div className="span-1">
                          <h6 className="top-number">04</h6>

                          <h4>24 Survey Respondents</h4>
                          <p className="project-study-inner-small">Finally, we created a survey that was completed by 24 people. The survey aimed to understand how people feel about NFTs, their value, and their desire for sharing NFTs. We targeted both NFT veterns and new people to the NFT space.</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              </div>
              <div id="dprin" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Insights</p>
                    <p>006</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>Major <span className="header-serif">Insights & findings</span> from research</h2>
                    <p className="project-study-inner">We created a set of design principals to guide our design explorations. These principals centered around both end users and the eventual designers who would use the system.</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">67%</h6>
                        <h4>Of participants want to keep NFTs private</h4>
                        <p className="project-study-inner-small">When looking to purchase and share NFTs users are worried about scamming and becoming targets for theft. </p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">57%</h6>
                        <h4>Of participants use multiple platforms </h4>
                        <p className="project-study-inner-small">To research, purchase, share and trade NFTs there are different platforms, wallets and markets to use. There is no centralized way for users to complete all of these tasks.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">69%</h6>
                        <h4>Of participants see NFTs as investments</h4>
                        <p className="project-study-inner-small">While NFTs do have their artistic value, most NFT veterans are interested in NFTs for their investment value.</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div style={{ backgroundImage: "url('/assets/finsoftbg.jpg')"}} className="chall-bg">
              <div className="case-study-body" >
                    <div style={{ marginTop: "24px", color: '#fff' }}>
                        <p>Problem Statement</p>

                      <div className="">
                        <h2 style={{ lineHeight: "150%"}}>
                          Currently, sharing and validating NFTs is a {" "}
                          <span className="header-serif">risky</span>,  <span className="header-serif">impersonal</span>, and <span className="header-serif">fragmented</span> experience. How can we help users safely share the NFTs they want to share and understand the value of NFTs they encounter?
                        </h2>
                      </div>
                    </div>
                  </div>
              </div>


              <div className="bigger-bg" style={{marginTop: "-64px"}}>
              <div id="explore" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Empathizing</p>
                    <p>007</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                      <h2>Introducing our persona <span className="header-serif">Nathan</span></h2>
                    <p className="project-study-inner">
                      We designed our persona Nathan to help us empathize with the experiences of NFT holders and lovers. Building this persona helped us ground every design decision in research. 
                    </p>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/fin-persona.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/fin-persona.jpg"
                  }}
                />
              </div>
              </div>

              <div id="testing" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>As-Is Experience</p>
                    <p>008</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>Customer <span className="header-serif">Journey map</span> for NFT sharing</h2>
                    <p className="project-study-inner">We created a customer journey map to align all stakeholders on the existing experience. Having the current experience mapped out highlighted the areas with pain points.</p>
                  </div>
                      <ImageZoom
                        image={{
                          src: "/assets/as-is.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto" }
                        }}
                        zoomImage={{
                          src: "/assets/as-is.jpg"
                        }}
                      />


                </div>
                </div>

                <div className="bigger-bg">
                <div className="case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Usability Testing</p>
                    <p>009</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                      <h2>NFT <span className="header-serif">profile pictures</span> highlighting rarity</h2>
                    <p className="project-study-inner">
                      We made NFT profile pictures a recognizable hexagon with a softer border-radius to match the design system guidelines. To add a moment of delight, I had the idea of adding a colored glow on hover to mimic video game item rarity.</p>
                  </div>
                </div>
                  <div className="grid-4">
                    
                      <ImageZoom
                        image={{
                          src: "/assets/fin-glow.gif",
                          className: "img span-2",
                          style: { width: "100%", margin: "auto" }
                        }}
                        zoomImage={{
                          src: "/assets/fin-glow.gif"
                        }}
                      />

                    <ImageZoom
                      image={{
                        src: "/assets/fin-pfp.png",
                        className: "img span-2",
                        style: { width: "100%", margin: "auto" }
                      }}
                      zoomImage={{
                        src: "/assets/fin-pfp.png"
                      }}
                    />
                    <ImageZoom
                      image={{
                        src: "/assets/fin-glow.png",
                        className: "img span-4",
                        style: { width: "100%", margin: "auto" }
                      }}
                      zoomImage={{
                        src: "/assets/fin-glow.png"
                      }}
                    />

                  </div>
                </div>
              </div>

              <div id="iter" className="mid-width case-study-body section-spacer no-bottom">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Digging Deeper</p>
                    <p>010</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                    <h2>NFT <span className="header-serif">Details</span> to understand value</h2>
                    <p className="project-study-inner">Users described a <b>fragmented</b> experience. They would visit multiple different platforms to find information. NFT holders were looking for ways to validate if the NFT they found is actually valuable. We created a details popup that provides users with all of the information they need in one place. 
                    </p>
                  </div>

                </div>
              </div>

                <ImageZoom
                  image={{
                    src: "/assets/fin-details.png",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/fin-details.png"
                  }}
                  
                />


              <div id="nstar" className="no-bottom mid-width case-study-body section-spacer " style={{ marginBottom: '0px !important;'}}>
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Guiding interface</p>
                    <p>011</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                    <h2> Curating gallery by <span className="header-serif">hiding & pinning</span> NFTs</h2>
                    <p className="project-study-inner">To address <b>fear of theft</b>, we added a hiding feature. Users are able to hide and show exactly the NFTs they want so they can feel secure in who people know about their portfolio
                    </p>
                  </div>

                </div>


              </div>

              <ImageZoom
                image={{
                  src: "/assets/fin-curate.png",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/fin-curate.png"
                }}
              />

              <div id="dprin" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Usability Testing</p>
                    <p>012</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>Usability Test <span className="header-serif">findings & iterations</span></h2>
                    <p className="project-study-inner">We conducted usability tests with 5 NFT holders, one of whom is a frequent Finary user. We were looking to test clarity, completion and comprehension of the different features we made.</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-pfp-old.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-pfp-old.png"
                          }}

                        />
                        <h4 className="before-iter">Before</h4>
                        <p className="project-study-inner-small">Some users had a hard time identifying NFT profile pictures. They mentioned the edges being too rounded, making them look like circles in smaller sizes.</p>
                      </div>
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-pfp-new.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-pfp-new.png"
                          }}

                        />
                        <h4 className="after-iter">After</h4>
                        <p className="project-study-inner-small">We sharpened the border-radius just a little bit to make it contrast better beside a circle avatar, while also keeping the roundedness. </p>
                      </div>
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-details-old.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-details-old.png"
                          }}

                        />
                        <h4 className="before-iter">Before</h4>
                        <p className="project-study-inner-small">Users wanted the sidebar to take up more space. They also wanted more details about the artists and to reorder the section.</p>
                      </div>
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-details-new.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-details-new.png"
                          }}

                        />
                        <h4 className="after-iter">After</h4>
                        <p className="project-study-inner-small">We widened the sidebar, added rarity colors, artist details and reorganize the sections based on user feedback </p>
                      </div>
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-sort-old.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-sort-old.png"
                          }}

                        />
                        <h4 className="before-iter">Before</h4>
                        <p className="project-study-inner-small">Users mentioned wanting bulk editing and ordering features.</p>
                      </div>
                      <div className="span-1">
                        <ImageZoom
                          image={{
                            src: "/assets/fin-sort-new.png",
                            className: "img",
                            style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                          }}
                          zoomImage={{
                            src: "/assets/fin-sort-new.png"
                          }}

                        />
                        <h4 className="after-iter">After</h4>
                        <p className="project-study-inner-small">We added a sorting dropdown that allows users to sort A-Z, floor price and last added.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="testing" className="mid-width case-study-body section-spacer no-bottom">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Hi-Fi</p>
                    <p>013</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                    <h2>Final <span className="header-serif">designs </span> for NFT gallery & sharing</h2>
                    <p className="project-study-inner">With that, we completed the capstone and the project with Finary and Electropath the design agency. Our last step was a closing meeting showing our work and handing over the designs.</p>
                  </div>
                </div>
              </div>
              <video autoPlay loop muted width="100%" className="span-3" style={{marginBottom: "-70px"}}>
                <source src="/assets/finary.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="bigger-bg">
              <div id="reflect" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>What I Learned</p>
                    <p>014</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                      <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                    <p className="project-study-inner">
                      Working on this capstone while also juggling a full course load was challenging at times. However, NFTs and Web3 is a really interesting problem space with a lot of unanswered questions. Working on this project was an amazing learning experience and we had the best clients. </p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                          <h4>Divide & Conquer</h4>
                          <p className="project-study-inner-small">At first, we struggled with dividing work. With 6 people on the team, it was hard to make sure everyone got to work on what they wanted. We then divided up into research and design pods and that helped us parallelize work and finish faster while doing work we enjoyed.</p>
                      </div>
 
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        <h4>Value is what you make of it</h4>
                        <p className="project-study-inner-small">It was interesting exploring the NFT world and learning about NFT investment works. From the outside, NFTs may just seem like images but they are more complex than that. I learned just how quickly things can change and that value is in the eye of the beholder.</p>
                      </div>
                      </div>
                      </div>
                      </div>
                  </div>
                </div>

                
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finary;
