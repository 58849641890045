import React from 'react';
import CaseInfo from './CaseInfo';
import './Mi.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';

function Mi() {
  return (
    <div className="App project-study Mi-case">
      <CaseHeader
        image="/assets/mimain.svg"
        class="mi-sched"
      />
      <div>
        <div className="case-study-navi" id="nav">
        </div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                link="https://mischeduler.netlify.app/"
                linkname="View Site"
                image="/assets/mimain.svg"
                title="MI Scheduler"
                desc="A web app for building a course schedule for the Master of Information"
                overview="For the fall & winter semester of the Master of Information program, the faculty released the courses offered  on an excel sheet. This excel sheet made the process of creating a class schedule cumbersome. You'd have to look up classes and use a third party time table scheduler. I believed there could be an easier way to build a fall & winter timetable."
                problem="How can we make it easier for MI students to build a course schedule at the University of Toronto?"
                outcome="I built a course scheduler within a week and reached 81 visiters the day before course selection."
                role="Designer & Developer"
                timeline="1 week"
                tools={['Figma', 'ReactJS']}
                class="mi-sched"
              />
            </div>


            <video autoPlay loop muted width="100%" style={{ marginTop: '32px' }}>
                <source src="/assets/misched2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

            <div className="mid-width case-study-body case-study-body-section">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>The Problem</p>
                  <p>004</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Excel sheet timetables make <span className="header-serif">course planning</span> hard</h2>
              <p className="project-study-inner" style={{ marginBottom: '64px' }}>The fall and winter course offerings were in 2 separate excel sheets. This meant that each class had to be looked up manually and building the time timetable meant using a third party app or writing it down. This was a pain for many students. Some used figma to try and build a course schedule while others just imagined it. As you can imagine, selecting classes took a lot longer than anticipated.</p>
              </div>
              <ImageZoom
                image={{
                  src: "/assets/miold.jpg",
                  className: "img",
                  style: { width: "100%", marginBottom: 20 }
                }}
                zoomImage={{
                  src: "/assets/miold.jpg"
                }}
              />
                 
          </div>
            <div className="mid-width case-study-body case-study-body-section">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>The Scheduler</p>
                  <p>005</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>The Solution: An <span className="header-serif">interactive schedule builder</span> for the UXD program</h2>
              <p className="project-study-inner" style={{ marginBottom: '64px' }}>I converted the excel sheet into a json object so that it was easier to parse. With it, I used a fuzzy search input library so users can search for a class code or title. Users can then add lecture, tutorial or practical times for the classes they picked. From the schedule, users can click on an added class which will bring back the class options that they can update. After receiving feedback, I added a remove class button.</p>
              </div>
            </div>

            <div className="potluck-3-grid">
              <div style={{ backgroundColor: "#6B3044"}}>
                <ImageZoom
                  image={{
                    src: "/assets/mi-left.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/mi-left.jpg"
                  }}
                />
              </div>
              <div className="span-2">
                <ImageZoom
                  image={{
                    src: "/assets/mi-right.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/mi-right.jpg"
                  }}
                />
              </div>


            </div>

            
            <div className="case-study-body-section mid-width case-study-body">

                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Building for the user</p>
                    <p>006</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                <h2>Positive feedback & <span className="header-serif">future requests</span></h2>
                  <p className="project-study-inner" style={{ marginBottom: '64px' }}>After my initial launch, I let my fellow MI candidates test the interface and provide some feedback. This included things like a way to remove classes and exporting classes into a calendar or image. After some iteration and feedback, I received a lot of positive feedback and 81 individuals using the application by the start of course selection.  </p>
                </div>
                <ImageZoom
                  image={{
                    src: "/assets/mi-feedback.jpg",
                    className: "img",
                    style: { width: "100%", marginBottom: 20 }
                  }}
                  zoomImage={{
                    src: "/assets/mi-feedback.jpg"
                  }}
                />

              <p className="project-study-inner" style={{ marginBottom: '64px' }}>Since its initial launch in 2021, my tool has had 539 new users and 1.3k sessions. I have continued to update the site for every new year, which is highlighted by the spikes in usage in the fall. </p>

              <ImageZoom
                image={{
                  src: "/assets/mistats.png",
                  className: "img",
                  style: { width: "100%", marginBottom: 20 }
                }}
                zoomImage={{
                  src: "/assets/mistats.png"
                }}
              />


              </div>

            <div className="bigger-bg">
            <div className="mid-width case-study-body">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>Retrospective</p>
                  <p>007</p>
                </div>
              </div>
              <div style={{ marginTop: '24px' }}>
                <div className="bsc-2-grid">
                    <h2><span className="header-serif">Reflecting</span> on the project outcome</h2>
                  <p className="project-study-inner">
                    This project was born out of my personal need and ended up helping a bunch of other people too. I'm proud to have built something that was so useful for others.</p>
                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                        <h6 className="top-number">01</h6>
                      <h4>Ship it anyways</h4>
                      <p className="project-study-inner-small">I started building this tool a week before actual course selection. I thought against sharing it with others given the timeline. Sharing it anyways ended up helping close to 81 people which goes to show that it's never too late to share a solution.  </p>
                    </div>
                    <div className="span-1">
                        <h6 className="top-number">02</h6>
                      <h4>Mobile & Export</h4>
                      <p className="project-study-inner-small">For the next iteration, some of my fellow MI candidates asked for a mobile friendly version as well as a way to export the calendar either as an image or to google calendar or iCal. </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>


            </div>
        </div></div>
      </div>
    </div>
  );
}

export default Mi;
