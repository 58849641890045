import React from 'react';
import CaseInfo from './CaseInfo';
import './Meta.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
// import Nav from '../Nav';


function Meta() {
  return (
    <div className="App project-study Meta-case">
      {/* <Nav
        items={[
          "overview",
          "role",
          "timeline",
          "collab",
          "stats",
          "team",
          "fun",
          "reflection"
        ]}
      >
        <li><a href="#overview">Overview</a></li>
        <li><a href="#role">My Role</a></li>
        <li><a href="#timeline">Timeline</a></li>
        <li><a href="#collab">Collaboration</a></li>
        <li><a href="#stats">Stats</a></li>
        <li><a href="#team">Team</a></li>
        <li><a href="#fun">Fun</a></li>
        <li><a href="#reflection">Reflection</a></li>
      </Nav> */}
      <CaseHeader
        image="/assets/meta-home.svg"
        class="meta"
      />
      <div>
        <div className="case-study-navi" id="nav">
        </div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/meta-home.svg"
                title="Meta"
                desc={<span>My time at Meta as a <span className="header-serif">Product Design Intern</span> on the horizontal team called Product Foundations</span>}
                overview={<span>I spent the summer of 2022, having an absolute blast  working in-person at Meta as a product designer. I learned how important it was to keep cross functional teams in sync with your work. This goes beyond just dev, DS or engineering. It also includes designers on other teams who have stake in the work you are doing. My projects focused on the unification of different experiences on Facebook.  <b>My work is under NDA. This case study only shows my main learnings</b></span>}
                role="Product Design Intern"
                timeline="May 2022 - Aug 2022"
                tools={['Figma','Figjam', 'ReactJS', 'SASS', 'NextJs']}
                class="meta"
              />
            </div>

            <div className="bigger-bg" id="role">
              <div className="mid-width case-study-body">
                <div className="section-header-container about no-bottom">
                  <div className="section-header">
                    <p>General Responsibilities </p>
                    <p>003</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title">
                    <div className="bsc-2-grid">
                      <h2>Overview & <span className="header-serif">responsibilities</span></h2>

                      <p className="project-study-inner">I was given permission to make my internship exactly what I wanted. I had a core project but I was encouraged to take on everything and anything I wanted. Here are some of my responsibilities.</p>
                    </div>
                  </div>
                </div>

                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">01</h6>
                      <h4>Gaining Context</h4>
                      <p className="project-study-inner-small">To have a better understanding of the problem, I explored existing data and research from Facebook on this problem. I also set up 1:1s with PMs, engineers and designers from different teams to learn more about the problem. </p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">02</h6>

                      <h4>Interfacing with cross functional peers</h4>
                      <p className="project-study-inner-small">Since I was part of a horizontal product foundations team, I spent time talking to other designers about their product surfaces. I also worked with PMs and engineers to learn more about our existing systems </p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">03</h6>

                      <h4>Designing</h4>
                      <p className="project-study-inner-small">I used Figma and the Facebook design system to design solutions for 3 product areas. I iterated over solutions and led a brainstorming session in FigJam. I also built a clickable prototype.</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">04</h6>

                      <h4>Coding Prototypes & fixing bugs </h4>
                      <p className="project-study-inner-small">With my engineering background, I was able to code out a prototype of my core project to understand how seamless of an experience it could be. I was also ale to fix small UI bugs on Facebook that was pushed out to Facebook's billions of users.</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">05</h6>

                      <h4>Updating Design Systems & building frameworks </h4>
                      <p className="project-study-inner-small">Since I was part of the product foundations team, I supported the design system for Facebook web. I made updates to the design system and wrote frameworks for multiple systems</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
 


            <div id="timeline" className="mid-width case-study-body case-study-body-section section-spacer no-bottom">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Chronological Order</p>
                  <p>004</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Summer &  <span className="header-serif"> project timeline</span></h2>
                <p className="project-study-inner">Although I can't share the details of what I worked on, here is the basic timeline I followed. My favourite part was building a prototype and breaking down a large project into smaller sections for testing.</p>
              </div>
              <ImageZoom
                image={{
                  src: '/assets/metatimeline.svg',
                  className: 'img',
                  style: { width: '100%', marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/metatimeline.svg',
                }}
              />
            </div>







            <div className="bigger-bg" id="collab">
              <div  className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Ways of working</p>
                    <p>005</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Hybrid model of <span className="header-serif">working together</span></h2>
                  <p className="project-study-inner">Although the internship was in person, many of my team members were working in a remote or hybrid position and I had to combat loneliness and trying to find ways to feel more connected to my team. Figma came in really handy for this! Here is us highfiving each other during a team brainstorming workshop I ran for my core project. </p>
                </div>
                  <ImageZoom
                    image={{
                      src: '/assets/highfive.jpg',
                      className: 'img',
                      style: { width: '100%', marginTop: 50}
                    }}
                    zoomImage={{
                      src: '/assets/highfive.jpg',
                    }}
                  />
              </div>
              </div>

            <div id="stats" className="mid-width case-study-body case-study-body-section section-spacer">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>The Numbers</p>
                  <p>006</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Summer <span className="header-serif">Statistics</span></h2>
                <p className="project-study-inner">My internship was full of fun and cool achievements. Here are the numbers that represented my time at Meta</p>
              </div>
              <div className="info">


                <div className="potluck-3-grid text_center sur_stats">
                  <div className="span-1">
                    <h3 className="stat">4,773</h3>
                    <p className="research" width="200px">
                      Lines of code written
                    </p>
                  </div>
                  <div className="span-1">
                    <h3 className="stat">33</h3>
                    <p className="research" width="200px">
                      Pull requests reviewed
                    </p>
                  </div>

                  <div className="span-1">
                    <h3 className="stat">25</h3>
                    <p className="research" width="200px">
                      Figma Design system variants updated
                    </p>
                  </div>

                  <div className="span-1">
                    <h3 className="stat">17</h3>
                    <p className="research" width="200px">
                      Pull Requests Made
                    </p>
                  </div>

                  <div className="span-1">
                    <h3 className="stat">7</h3>
                    <p className="research" width="200px">
                      UI Bugs fixed
                    </p>
                  </div>

                  <div className="span-1">
                    <h3 className="stat">+1mil</h3>
                    <p className="research" width="200px">
                      Snacks eaten
                    </p>
                  </div>

                </div>

              </div>
            </div>
            

            <div id="team" className="bigger-bg" style={{paddingBottom: '0px'}}>
              <div className="mid-width case-study-body no-bottom">

                <div className="section-header-container about">
                  <div className="section-header">
                    <p>The Team</p>
                    <p>007</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>My core<span className="header-serif"> team members</span></h2>
                  <p className="project-study-inner">My team was a small group of designers and one Design Program Manager. They helped me become a better designer. Each person had their own strengths that I relied on to have the best experience that I could.  </p>
                </div>

                <ImageZoom
                  image={{
                    src: '/assets/metapeeps.png',
                    className: 'img',
                    style: { width: '100%', marginTop: 50 }
                  }}
                  zoomImage={{
                    src: '/assets/metapeeps.png',
                  }}
                />

            </div>
            </div>



            <div id="fun" className="mid-width case-study-body case-study-body-section section-spacer no-bottom">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>The Fun Stuff</p>
                  <p>008</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Being in person <br/>new <span className="header-serif">was a Californian Dream</span> come true</h2>
                <p className="project-study-inner">As cliche as it sounds, the summer was the stuff of dreams.Not only did it get to work on cool things and make an impact, I had fun doing it. We tried glass blowing, pottery, went to a baseball game and even ate to a Michelin star restaurant.</p>
              </div>

            </div>
            <ImageZoom
              image={{
                src: '/assets/funtimes.jpg',
                className: 'img span-2',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/funtimes.jpg',
              }}
            />


            

            <div id="reflection" className="bigger-bg">
              <div  className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Lessons Learned</p>
                    <p>009</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                  <p className="project-study-inner">While the summer was a blast, it came with its own challenges and learnings. Here are my main takeaways</p>
                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">01</h6>
                      <h4>Keep everyone in the loop</h4>
                      <p className="project-study-inner-small">Working in big tech meant more siloed teams. I had to make an effort to get to know cross functional teams. I also had to learn to be very vocal about the work I was doing to ensure everyone was aware and onboard with the changes I was proposing. This is an area I'd like to keep improving in.</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">02</h6>
                      <h4>How to work without a UX researcher</h4>
                      <p className="project-study-inner-small">My team was quite small and did not have a UX researcher. Instead, we would look at data science, existing research, critique designs and run small experiments based on the top contenders. This was difficult for me because I had to learn to trust my gut and not rely only on user data. I also had to learn which ideas should be broken down into experiments and articulate why. </p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">03</h6>
                      <h4>Trust thyself</h4>
                      <p className="project-study-inner-small">This was one of the hardest lessons. I came in with a lot of imposter syndrome. I felt like everyone around me was so much better than I was that I refrained from speaking up. My team helped me get over this by showing me how valuable they thought my contributions were and pushed me to b more vocal. This is something I'm continuing to work through</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">04</h6>
                      <h4>Knowing when to create prototypes</h4>
                      <p className="project-study-inner-small">Different levels of fidelity are needed depending on where in the project we are in. I had to learn when to abandon Figma and start coding my own prototypes and articulate why it was needed. It was helpful having a mockup website to build on top of.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            


        </div></div>
      </div>
    </div>
  );
}

export default Meta;
