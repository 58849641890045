import React from 'react';
import CaseInfo from './CaseInfo';
import './WishDC.css';
import CaseHeader from './CaseHeader';


function WishDC() {
  return (

    <div className="App project-study" style={{marginBottom: '120px'}}>
      <CaseHeader
        image="/assets/wishdc-top.png"
        class="wish"
      />
      <div className="case-study-body">

        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview">
              <CaseInfo
                link="https://docs.google.com/presentation/d/1vlGuvlSZWU_sVFhMgJbxdaHYiuTpMV310kyBwO83kok/edit?usp=sharing"
                linkname="View Slide Deck"
                image="/assets/wish-top.png"
                title="Wish Design Challenge"
                desc="A design challenge showcasing my process for trying to improving engagement on the e-commerce mobile app Wish."
                overview="Wish is a e-commerce app aimed at make shopping fun. I worked on this case study as part of the Wish interview process. I was asked to improve the app in some way. I spent 4 days on this and had a blast trying to find a solution, iterating and testing."
                problem="How can we improve the Wish mobile app to have an impact on user acquisition, retention, engagement, sales or other attributes?"
                outcome="🎉 I received an offer from Wish and was told I had the highest interview scores.
91% of user testers agreed that my solution was more engaging than regular scrolling."
                role="Product Designer"
                timeline="4 Days"
                class="wish-dc"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishDC;
