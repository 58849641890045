import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './Home';
import Process from './Process';
import Header from './Header';
import Articles from './Articles';
import About from './About';
import Freelance from './Freelance';
import Fun from './Fun';
import Wish from './projects/Wish';
import Mi from './projects/Mi';
import Bsc from './projects/Bsc';
import Potluck from './projects/Potluck';
import WishDC from './projects/WishDC';
import Blueprint from './projects/Blueprint';
import Finary from './projects/Finary';
import Footer from './Footer';
import NotFound from './NotFound';
import ROM from './projects/ROM';
import Meta from './projects/Meta';
import Menti from './projects/Menti';
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: localStorage.getItem('darkTheme') === 'true' ? 'dark' : 'light'
    }
  }

  toggleDarkMode = () => {
    if (localStorage.getItem('darkTheme') === 'true') {
      localStorage.setItem('darkTheme', 'false')
      this.setState({theme: 'light'})
    } else {
      localStorage.setItem('darkTheme', 'true')
      this.setState({ theme: 'dark' })

    }
  }

  render() {
  return(
    <div>
      <div className='main'>
      <Router>
        <Header toggleDarkMode={this.toggleDarkMode}/>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/process" exact component={Process} />
          <Route path="/articles" exact component={Articles} />
          <Route path="/about" exact component={About} />
          <Route path="/freelance" exact component={Freelance} />
          <Route path="/fun" exact component={Fun} />
          <Route path="/projects/wish" exact component={Wish} />
          <Route path="/projects/mi" exact component={Mi} />
          <Route path="/projects/bsc" exact component={Bsc} />
          <Route path="/projects/rom" exact component={ROM} />
          <Route path="/projects/meta" exact component={Meta} />
          <Route path="/projects/menti" exact component={Menti} />
          <Route path="/projects/potluck" exact component={Potluck} />
          <Route path="/projects/wish-design-challenge" exact component={WishDC} />
          <Route path="/projects/finary" exact component={Finary} />
          <Route path="/projects/blueprint" component={Blueprint} />
          <Route component={NotFound} />
          </Switch>
          <Footer/>
      </Router>
      </div>
    </div>
  );
  }
}
export default App;
