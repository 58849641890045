import React from 'react';
import './Footer.css'
import FeatherIcon from 'feather-icons-react'

import { renderIcon } from './projects/Icon'
export default class Footer extends React.Component {

  renderMoreProjects = () => {
    return(
      <div className="more_projects">
        <div className="more_projects_inner">
        <h1>Check out more projects!</h1>

        <div className="contain">
          <a href="/projects/potluck" className="card" style={{ backgroundColor: "#6C69EA"}}>
            <img src="/assets/images/potluck.png" width='200' alt="Potluck Project" />
            </a>
          <a href="/projects/wishchallenge" className="card" style={{ backgroundColor: "#2FB7EA"}}>
            <img src="/assets/images/wish.png" width='200' alt="Wish Project" />
            </a>
          <a href="/projects/instura" className="card" style={{ backgroundColor: 'rgb(255, 214, 59)'}}>
            <img src="/assets/images/ins.png" width='200' alt="Instura Project" />
            </a>
          <a href="/projects/mento" className="card" style={{ backgroundColor: 'rgb(26, 43, 82)'}}>
            <img src="/assets/images/mento.png" width='100' alt="Mento Project" />
          </a>

          <a href="/projects/schedu" className="card" style={{ backgroundColor: 'rgb(65, 137, 255)'}}>
            <img src="/assets/images/schedu.png" width='150' alt="Schedu Project" />

          </a>
          <a href="/projects/moment" className="card" style={{ backgroundColor: 'rgb(160, 82, 255)'}}>
            <img src="/assets/images/momentchat.png" width='150' alt="Moment Project" />

          </a>
          <a href="/projects/parsehub" className="card" style={{ backgroundColor: 'rgb(255, 147, 150)'}}>
            <img src="/assets/images/parsehub.png" width='200' alt="Parsehub Project" />

          </a>
         
          <a href="/projects/walden" className="card" style={{ backgroundColor: 'rgb(88, 174, 237)'}}>
            <img src="/assets/images/walden1.png" width='200' alt="Walden Project" />
          </a>
        </div>
        </div>


      </div>
    );
  }
  render() {
    return (
      <div>
        {/* {window.location.href.includes('project') && this.renderMoreProjects()} */}
      <div className="footer">
          <div className="container">
            <div>
              <img src="/assets/logo-dark.svg" alt="logo"/>
              <h4>Thanks for visiting <br/> my portfolio</h4>
            </div>
            <div className="footer-links">
              <p>Made with <FeatherIcon size="20" icon="code" stroke="#6E998F" /> by me </p>
              <div className="footer-grid">
                <a href="mailto:taamannae@gmail.com">{renderIcon('mail')}</a>
                <a href="https://www.linkedin.com/in/taamannae/" target="_blank" rel="noopener noreferrer">
                  {renderIcon('linkedin')}
                </a>
                <a href="https://github.com/Taamannae" target="_blank" rel="noopener noreferrer">
                  {renderIcon('github')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}