import React, { Component } from 'react';
import PasswordStyles from './Password.module.css'
import FeatherIcon from 'feather-icons-react';

export default class Wish extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      error: ''
    }
  }

  componentDidMount = () => {
   document.getElementById('root').style.overflow = 'hidden'
   document.getElementById('root').style.position = 'fixed'
  }

  handlePassword = (e) => {
    this.setState({password: e.target.value})
  }

  handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    this.handleSubmit();
  }
}

  handleSubmit = () => {
    if (this.state.password === 'canYouMakeTheLogoBigger?') {
      localStorage.setItem('tamPasswordSet', true);
      document.getElementById('root').style.overflow = 'unset'
      document.getElementById('root').style.position = 'unset';
      window.location.reload();

    } else if (this.state.password === '') {
      this.setState({error: 'Please enter the password'})
    } else {
      this.setState({ error: 'Sorry! wrong password' })

    }
  
  }

  render() {
    return (
      <div className={PasswordStyles.background}>
        <FeatherIcon icon="lock" stroke="#000" stokeWidth="4px" /> <br />

        <h2 className={PasswordStyles.header}><span className="header-serif">Sorry!</span> You need a <br /><span className="header-serif">password</span> to see this project</h2>
        <div className={PasswordStyles.input_container}>
            <input type="password" onKeyDown={this.handleKeyDown} onChange={this.handlePassword} placeholder="Enter password" className={PasswordStyles.password_input}/>
            <button type="submit" onClick={this.handleSubmit} className={PasswordStyles.button}> Submit</button>
        </div>
        <p className={PasswordStyles.error}>{this.state.error}</p>


      </div>
    );
  }
}

