import React from 'react';
import './Articles.css';
import { renderIcon } from './projects/Icon'



const ARTICLES = [
  {
    id: 'chi',
    link: 'https://dl.acm.org/doi/10.1145/3411763.3451751',
    date: 'May 2021',
    number:'01',
    title: "Immigrant Families' Health- Related Information Behavior on Instant Messaging Platforms",
    desc: "CHI EA '21: Extended Abstracts of the 2021 CHI Conference on Human Factors in Computing Systems • May 2021 • Article No.: 439 • Pages 1–6 • https://doi.org/10.1145/3411763.3451751",
    auths: true,
  },
  {
    id: 'wish',
    link: 'https://taamannae.medium.com/a-wish-design-system-color-story-ef2c38de97e',
    date: 'Apr 5th 2021',
    number:'02',
    title: 'A Wish Design System Color Story',
    desc: 'During the winter of 2021, I had the chance to be a Product Design intern at Wish. I remember it being the first day of my internship and I was...'
  },
  {
    id: 'whattodo',
    link: 'https://medium.com/@taamannae/ux-design-projects-what-do-i-do-next-and-which-methodology-do-i-use-e879c0e9030c',
    date: 'Nov 15th 2020',
    number:'03',
    title: 'UX Design Projects: What do I do next, and which methodology do I use?',
    desc: 'You’ve started your UX project, or maybe you were given a problem to tackle. Perhaps you’ve done the research, or maybe you’re not sure where to even begin.'
  },
  {
    id: 'figma',
    link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    date: 'Sep 7th 2020',
    number:'04',
    title: 'Figma & Design Thinking: Building a Design System for an Existing Product',
    desc: 'When I joined Potluck as a UX Design intern, we were using Sketch, Craft, and InVision for our design-to-development pipeline.'
  },
  {
    id: 'google',
    link: 'https://medium.com/@taamannae/losing-a-google-ux-design-internship-to-covid-and-what-i-did-about-it-a774d270c6b9',
    date: 'Jun 1st 2020',
    number:'05',
    title: 'Losing a Google UX Design Internship to COVID and What I Did About it',
    desc: 'I remember when I applied for the internship. I kept refreshing the page until the application button went live.'
  },
  {
    id: 'neu',
    link: 'https://uxdesign.cc/neumorphism-visual-accessibility-and-empathy-d1c5ed2a1f03',
    date: 'May 13th 2020',
    number:'06',
    title: 'Neumorphism, visual accessibility, and empathy',
    desc: '“A good UX designer has empathy”. This is something every UX designer has heard at some point in their career. '
  },
  {
    id: 'exp',
    link: 'https://uxdesign.cc/expectations-vs-realities-ux-design-in-the-industry-vs-ux-design-at-school-51851e59a5eb',
    date: 'Oct 23th 2019',
    number:'07',
    title: 'Expectations vs Realities: UX design in the industry vs UX design at school',
    desc: 'If you’ve been to design school or taken a design course or even researched UX design, you’ll know how important the design process is'
  },


]
function Articles() {

  const renderArticle = () => {
    return ARTICLES.map(item => {
      return (
        <a href={item.link} target="_blank" key={item.id} className="article" rel="noreferrer">
          <div className="article-info">

            <div className="external">
              <h6 className="top-number">{item.number}</h6>
              <div>{renderIcon('external-link')}</div>
            </div>
            <h4 style={{ marginTop: '20px' }}>{item.title}</h4>
            <p className="project-study-inner-small">{item.desc}</p>
          </div>
        </a>
      )
    })

  }

  return (
    <div className="bigger-bg">
    <div className="App processBig top-top">
      <header className="process two-col">
        
        <div className="span-2">
            <h2>"Design is more than just <span className="header-serif">pixel pushing. </span> It's storytelling” 
          </h2>
        </div>
        <div className="span-2">
          <p className="process-para">I like to write about design, career growth and my general values. It is important for me to share ideas and if my articles help even one person, I’d consider it a success. </p>
        </div>
      </header>

      <div className="section-header-container">
        <div className="section-header">
          <p>Work</p>
          <p>001</p>
        </div>
      </div>

      <div className="publication bsc-2-grid">
        {renderArticle()}
      </div>
    </div>
    </div>
  );
}

export default Articles;
