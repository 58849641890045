import React from 'react';
import CaseInfo from './CaseInfo';
import './Blueprint.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';

function Blueprint() {
  return (
    <div className="App project-study Blueprint-case">
      <CaseHeader
        image="/assets/blueprint.jpg"
        class="Blueprint"
      />
      <div>
        <div className="case-study-navi" id="nav">
        </div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/ins.png"
                title="Blueprint"
                desc={<span>A web app for an undisclosed non-profit that helps in <span className="header-serif"> move coordination</span></span>}
                overview="Blueprint is a student run club that partners with nonprofits to deliver software solutions. For the safety and privacy of the organization, it will be referred to as Blueprint. I worked with a team of 9 students to create an event coordination and emailing system. We had regular client meetings to ensure needs were met. For privacy reasons, I can only share an overview of the designs and not describe the full case study."
                problem="Blueprint had set up many manual processes to help plan moves that involved many different applications. How can we automate their process and provide an all-in-one solution for move coordination?"
                outcome="I created a design system, lead a junior designer, delivered high fidelity mockups, worked with developers, interfaced with clients and delivered a software solution to improve their process."
                role="Lead Designer"
                timeline="Sep 2020 - Aug 2021"
                tools={['Figma', 'ReactJS']}
                team={["1 Junior Designer", "1 Project Manager", "1 Project Lead", "6 Developers"  ]}
                class="Blueprint"
              />
            </div>


            <video autoPlay loop muted width="100%" style={{ marginTop: '32px' }}>
                <source src="/assets/blueprint.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

            <div className="mid-width case-study-body case-study-body-section">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>Creating Consistency</p>
                  <p>004</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Building a robust <span className="header-serif">Design System</span></h2>
              <p className="project-study-inner" style={{ marginBottom: '64px' }}>I built our design system, Arctic, for our application with the help of our project manager & developers. The branding work was already done for this non-profit so the goal was to take the existing style guide and elevating it into a robust and scalable system.</p>
              </div>

                 
          </div>
          <div className="blueprint-mid-width">
            <div className="bsc-2-grid">
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/arctic.jpg",
                    className: "img",
                    style: { width: "100%", marginBottom: 20 }
                  }}
                  zoomImage={{
                    src: "/assets/arctic.jpg"
                  }}
                />
              </div>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/arctic2.jpg",
                    className: "img",
                    style: { width: "100%", marginBottom: 20 }
                  }}
                  zoomImage={{
                    src: "/assets/arctic2.jpg"
                  }}
                />
              </div>
            </div>
            <ImageZoom
              image={{
                src: "/assets/designsystem.jpg",
                className: "img",
                style: { width: "100%", marginBottom: 20 }
              }}
              zoomImage={{
                src: "/assets/designsystem.jpg"
              }}
            />
            </div>

            <div className="bigger-bg">
            <div className="mid-width case-study-body">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>Automation</p>
                  <p>005</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                  <h2>Making <span className="header-serif">Volunteer Waivers </span>Digital</h2>
                <p className="project-study-inner" style={{marginBottom: '64px'}}>Up until now, Blueprint manually collected waivers. Volunteers would have to sign the waiver on the day of the move in person. We introduced an automated waiver signing process that is easier for both the volunteer and event planner. For each move, the event planner has granular information of who has signed a waiver and who hasn't.</p>
                </div>


            </div>

                <div className="bsc-2-grid">
                  <div>
                    <ImageZoom
                      image={{
                        src: "/assets/waiver.jpg",
                        className: "img",
                        style: { width: "100%", marginBottom: 20 }
                      }}
                      zoomImage={{
                        src: "/assets/waiver.jpg"
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: "/assets/waiver-user.jpg",
                        className: "img",
                        style: { width: "100%", marginBottom: 20 }
                      }}
                      zoomImage={{
                        src: "/assets/waiver-user.jpg"
                      }}
                    />
                  </div>
                </div>

            </div>

            <div className="mid-width case-study-body case-study-body-section">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>Automation</p>
                  <p>006</p>
                </div>
              </div>
              <div className="bsc-2-grid">

                <h2>Automating Processes with <span className="header-serif">Templates</span></h2>
              <p className="project-study-inner" style={{ marginBottom: '64px' }}>Blueprint used a set of templates for most of their processes and they were kept in Google Docs. They needed a way to easily control which template was being used at any given point. We built templating directly into the experience so every new itinerary started with the right information and only managers had the ability to change which templates would be in use.</p>
              </div>


            </div>

            <div className="potluck-3-grid">
              <div style={{ backgroundColor: "#DFAA23"}}>
                <ImageZoom
                  image={{
                    src: "/assets/templateLeft.jpg",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/templateLeft.jpg"
                  }}
                />
              </div>
              <div className="span-2">
                <ImageZoom
                  image={{
                    src: "/assets/templates1.png",
                    className: "img",
                    style: { width: "100%" }
                  }}
                  zoomImage={{
                    src: "/assets/templates1.png"
                  }}
                />
              </div>


            </div>

            <div className="blueprint-quote-block">
              <h4 className="blueprint-quote" style={{fontSize: '72px'}}>“</h4>
              <h4 className="blueprint-quote">
                We're extremely lucky to have had the opportunity to collaborate with the team. Working with the team, under the creative and consistent leadership of <span className="header-serif">Tammy, Dakota and Michael</span> has been a wonderful experience. They are not only extremely diligent, but are thoughtful communicators who are very patient and listened carefully to every request and change we had for the software they are developing for us.

              </h4>

              <div className="blueprint-quote-names">
                <h5>Operations Director</h5>
              </div>
            </div>
            <div className="bigger-bg">
            <div className="mid-width case-study-body">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>Retrospective</p>
                  <p>007</p>
                </div>
              </div>
              <div style={{ marginTop: '24px' }}>
                <div className="title">
                  <div className="bsc-2-grid" style={{marginBottom: '70px'}}>

                    <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                  <p className="project-study-inner">
                    This project was a little rough at the beginning. We had a pivot point where the entire experience had to be re-evaluated. Despite the curve ball, we delivered something useful and delightful.</p>
                  </div>

                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">01</h6>
                      <h4>Mentor with scope</h4>
                      <p className="project-study-inner-small">As a leader, mentoring someone newer to design, I learned how to scope down problems in a way that felt challenging but also approachable. </p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">02</h6>
                      <h4>Lead with radical compassion</h4>
                      <p className="project-study-inner-small">Since this was a student club, there were times where people were busy with school. As a leader, I learned to plan with flexibility.</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            </div>
        </div></div>
      </div>
    </div>
  );
}

export default Blueprint;
