import React, { Component } from 'react';
import './About.css';
import Gallery from "react-photo-gallery";

export const photos = [
  {
    src: '/assets/images/newone.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint1.png',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/20.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/29.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/30.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/24.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/6.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/7.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/8.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/9.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/21.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/10.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/11.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/12.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/13.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/14.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/15.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/16.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/17.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/18.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/19.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/22.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/23.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/25.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/26.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/27.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/28.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/31.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/33.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/32.jpg',
    width: 4,
    height: 3
  },

  {
    src: '/assets/images/34.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/35.jpg',
    width: 4,
    height: 3
  },
];

class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false
    }
  }
  setCurrentImage = (index) => {
    this.setState({
      currentImage: index
    })
  }

  setViewerIsOpen = (bool) => {
    this.setState({
      viewerIsOpen: bool
    })
  }
  render() {
  return (
    <div className="App">
    <div className="about-outside">
      <div className="about-me top-top">
        <div className="about-picture">
          <div className="about-me-pic" style={{ backgroundImage: "url('./assets/me2.png')"}}/>
        </div>
        <div className="about-info">
          <div className="section-header-container about">
            <div className="section-header" style={{ paddingTop: 0, marginBottom: 40 }}>
              <p>About Me</p>
              <p>001</p>
            </div>
          </div>

          <h1 className="About-me-title">Hello, I'm Taamannae, but you can <span className="header-serif">call me Tammy</span></h1>
          <h4>/ta•man•nah/</h4>
          <h3>I am a product designer based in Toronto, Canada. I value human connection, vulnerability, accessibility, equity, growth & openness. I enjoy solving difficult human problems through design</h3>
          <p className="about-p">
          Previously, I was at Meta, Xbox, Wish, Saltwater, Not Dev and Octoshop. I'm currently pursuing my Master of Information at UofT in UX Design. Before that, I completed my Bachelor of Science in Neuroscience, Computer Science and Stats at UofT.
          <br/>
          <br/>
              As a designer with an engineering background and illustration skills, I am specially equipped to act as a bridge between design and dev teams. I use my diverse background to make informed choices fast. If you're curious about what it is like to work with me, feel free to read my <a href="https://dazzling-whistle-93f.notion.site/Tammy-User-Manual-88d80690cfe740cdb016b812dd48fdc0" target="_blank" rel="noreferrer">User Manual</a>
            <br />
            <br />
          When I'm not trying to solve puzzles and designing, you can find me, playing video games, ️travelling, reading, painting, illustrating, taking  photographs of the world around me and writing on Medium.
          <br/>
          <br/>
          I strive to be a good designer and a good mentor. My goal is to one day be a compassionate, open minded and approachable design leader but right now, I am having a blast designing and developing cool things.
          </p>

          <div className="section-header-container about">
            <div className="section-header">
              <p>Achievements</p>
              <p>003</p>
            </div>
          </div>

          <div className="achievements">
            <div>
              <p className="ach-prize">1st place & XD prize</p>
              <p className="ach-place">UXResult</p>
            </div>
            <div>
              <p className="ach-prize">Editor's Pick</p>
              <p className="ach-place">Bestfolio</p>
            </div>
            <div>
              <p className="ach-prize">Best Continued Hack</p>
              <p className="ach-place">Hack:Now</p>
            </div>
            <div>
              <p className="ach-prize">1st place</p>
              <p className="ach-place">Adobe XD Contest</p>
            </div>
            <div>
              <p className="ach-prize">Excellence Award</p>
              <p className="ach-place">University of Toronto</p>
            </div>
            <div>
              <p className="ach-prize">Finalist - 2020</p>
              <p className="ach-place">Covid Global Hackathon</p>
            </div>

          </div>
        
        </div>

      </div>

      <div className="about-me-bottom">
        
       


      </div>

      <Gallery photos={photos} direction={"column"} id="lightbox" />
    </div>
    </div>
  );
  }
}

export default About;
