import React from 'react';
import CaseInfo from './CaseInfo';
import './Bsc.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
import Slider from "react-slick";
import Password from "./Password";
import './Slick.css';

function Bsc() {


  const lofi = {
    customPaging: function (i) {
      return (
        <span className="cara_img">
          <img src={`/assets/bsc${i}.jpg`} alt="Low Fidelity Issues and Solutions" width="100%" />
        </span>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const audit = {
    customPaging: function (i) {
      return (
        <span className="cara_img">
          <img src={`/assets/bscaudit${i}.jpg`} alt="Low Fidelity Issues and Solutions" width="100%" />
        </span>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const cat = localStorage.getItem('tamPasswordSet');
  if (!cat) {
    return (
      <Password />
    )
    
  }

  return (
    <div className="App project-study">

      <CaseHeader
        image="/assets/bsc-top.png"
        class="bsc"
      />
      <div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/bsc-top.png"
                title="Boston Scientific"
                desc={<span>A consistent and <span className="header-serif"> accessible design system</span> for Boston Scientific's suite of products</span>}
                overview="Boston Scientific is a health tech company focused on improving the health of their users with innovative technologies. They have a number of mobile apps that, although are part of Boston Scientific, do not have a consistent design. Boston Scientific wished to create a more cohesive design across all of these mobile applications. They hired Not Dev, the design agency I worked at, to lead this project."
                problem="Create a cohesive design system that is also accessible to Boston Scientific's older users and that can be used across all apps."
                outcome="We delivered a design system in 4 months. I worked on a icon set that improved in context recognition from 56% to 98%"
                role="Product Designer"
                timeline="Sep 2020 - Dec 2020"
                tools={['Figma']}
                class="bsc"
                team={['Me', 'Dev Gupta', 'Tolu Ajayi', 'Alicia Rizo-liu', 'Sam Dayoub']}
              />
              </div>
              <div className="bigger-bg">
              <div className="mid-width case-study-body">

              <div className="section-header-container about">
                <div className="section-header">
                  <p>End Result</p>
                  <p>004</p>
                </div>
              </div>

              <div style={{ marginTop: '24px' }}>
                <div className="title">
                  <div className="bsc-2-grid">
                      <h2>Final <span className="header-serif">Design System</span> for Boston Scientific</h2>

                    <p className="project-study-inner">Over the course of 4 months, we worked to deliver a comprehensive design system for Boston Scientific that alined with the company vision, created a more trustworthy and modern appearance and was easier to use by Boston Scientific's older adult demographic.</p>
                  </div>
                  <div className="slider-cont">
                    <Slider {...lofi} >
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bsc0.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bsc0.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bsc1.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bsc1.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bsc2.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bsc2.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bsc3.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bsc3.jpg',
                          }}
                        />
                      </div>

                    </Slider>
                  </div>
                </div>
                </div>

</div>
            </div>
            <div className="project-study-inner section-spacer">
              <div id="process" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Design Flow</p>
                    <p>005</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title bsc-2-grid">
                    <h2>Our System <span className="header-serif"> Design Process</span> & steps</h2>
                    <p className="project-study-inner">Our process involved digging into Boston Scientific's existing styles, finding issues, exploring new styles, testing ideas and finally, delivering the system.</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Audit</h4>
                        <p className="project-study-inner-small">It I owned the design system's color tokens from conception, auditing, iteration to delivery.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        
                        <h4>Design Principals</h4>
                        <p className="project-study-inner-small">We decided on what principals to follow to create the most effective system</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">03</h6>
                        
                        <h4>Design</h4>
                        <p className="project-study-inner-small">We explored different styles and ideas for the design system in Figma.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">04</h6>
                        
                        <h4>Test</h4>
                        <p className="project-study-inner-small">We tested our hypotheses for icon styles, style reception and accessibility.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">05</h6>
                        
                        <h4>Iterate</h4>
                        <p className="project-study-inner-small">We iterated over and over with the Boston Scientific design team to land the perfect system.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">06</h6>
                        
                        <h4>Deliver</h4>
                        <p className="project-study-inner-small">We delivered the final accessible Figma system with northstar screens and modular components.</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              </div>
              <div>
              <div className="bigger-bg">
              <div id="audit" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Understanding the current system</p>
                    <p>006</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                      <h2><span className="header-serif">Auditing</span> the existing application</h2>
                    <p className="project-study-inner">We conducted an audit by pulling out all of the different types of design components and their uses. We also checked colour contrast issues and inconsistencies in type of the current design system to drive the value of a new design system.</p>
                  
                  
                  </div>
                  <div className="slider-cont">
                    <Slider {...audit} >
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bscaudit0.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bscaudit0.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bscaudit1.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bscaudit1.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bscaudit2.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bscaudit2.jpg',
                          }}
                        />
                      </div>
                      <div>
                        <ImageZoom
                          image={{
                            src: '/assets/bscaudit3.jpg',
                            className: 'img',
                            style: { width: '100%', marginTop: 50 }
                          }}
                          zoomImage={{
                            src: '/assets/bscaudit3.jpg',
                          }}
                        />
                      </div>

                    </Slider>
                  </div>
                </div>
              </div>
              </div>

              <div id="dprin" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Guiding Ideas</p>
                    <p>007</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>Creating <span className="header-serif">Design Principles</span> to follow</h2>
                    <p className="project-study-inner">We created a set of design principals to guide our design explorations. These principals centered around both end users and the eventual designers who would use the system.</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Accessible</h4>
                        <p className="project-study-inner-small">Since our users are older, accessibility was our top priority. Our colour system had to pass AA colour contrast and text styles eligible.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        <h4>Easy to use</h4>
                        <p className="project-study-inner-small">We have to build a robust, modular, scalable and maintainable system that makes designing easy for designers on the Boston Scientific team.</p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">03</h6>
                        <h4>Approachable</h4>
                        <p className="project-study-inner-small">As a health tech company, apps need to impart a feeling of trust. We wanted the system to feel comfortable but also trustworthy.</p>
                      </div>

                    </div>

                  </div>

                </div>


              </div>
              <div className="bigger-bg">
              <div id="explore" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Ideation</p>
                    <p>008</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                      <h2>Exploring <span className="header-serif">different styles</span> & layouts</h2>
                    <p className="project-study-inner">
                      We tried different styles and took inspiration from fitness apps. Here is a small range of ideas we explored.
                    </p>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/bscexplore.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/bscexplore.jpg"
                  }}
                />
              </div>
              </div>

              <div id="testing" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Emojis & Imagery</p>
                    <p>009</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                    <h2>Pictograms for <span className="header-serif">pain, sleep & activity</span></h2>
                    <p className="project-study-inner">Pictograms are images used to represent pain, activity and sleep in the Boston Scientific System. They have three severity levels: less, same and more. I had a hypothesis that the existing icons were too similar, making it difficult to understand what they represented, and so I created a new set of pictograms to test.</p>
                  </div>
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscold1.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto" }
                        }}
                        zoomImage={{
                          src: "/assets/bscold1.jpg"
                        }}
                      />
                      <p className="caption"> <b>Existing:</b> Each pictogram set is hard to identify.</p>
                    </div>
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscnew1.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto" }
                        }}
                        zoomImage={{
                          src: "/assets/bscnew1.jpg"
                        }}
                      />
                      <p className="caption"><b>Proposed:</b> Each pictogram belongs to a well defined set with notable variance within each set.</p>
                    </div>

                  </div>

                </div>
                </div>

                <div className="bigger-bg">
                <div className="case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Usability Testing</p>
                    <p>010</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                      <h2>Testing <span className="header-serif">Pictograms</span> for coherence</h2>
                    <p className="project-study-inner">
                      My colleague, Sam, led testing for the pictograms. He tested the pictograms both in context and without context and colour taken away. Reception of the existing pictograms were tested with 107 people and the proposed ones with 50 people.</p>
                  </div>
                    <div className="section-header">
                      <p>Usability Testing</p>
                      <p>010</p>
                    </div>
                  <div className="bsc-2-grid">
                  <h4 style={{ marginTop: '64px' }}>Question Type 1: “Which Icon represents that you ________?”</h4>
                  <p className="project-study-inner">For this set of questions, colour and in app context was stripped away and the pictograms were randomized. We asked testers to identify which pictogram represented a category and severity. For example, Which Icon represents that you are in more pain?”
                  </p>
                  </div>

                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscold.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto" }
                        }}
                        zoomImage={{
                          src: "/assets/bscold.jpg"
                        }}
                      />
                        <p className="caption"> <b>Existing:</b> Average 59% correct</p>
                    </div>
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscnew.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto" }
                        }}
                        zoomImage={{
                          src: "/assets/bscnew.jpg"
                        }}
                      />
                        <p className="caption"><b>Proposed:</b> Average 82% correct</p>
                    </div>

                  </div>

                    <div className="section-header">
                      <p>Usability Testing</p>
                      <p>010</p>
                    </div>
                    
                    <div className="bsc-2-grid">
                  
                  <h4 style={{marginTop: '0px !important'}}>Question Type 2: “Find the icon inside the purple box. What category does it belong to?”</h4>
                  <p className="project-study-inner">For this set of questions, colour and in app context was added. We asked testers to identify the category of the pictogram in the purple box.
                  </p>
                </div>
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscq2old.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto", borderRadius: '20px' }
                        }}
                        zoomImage={{
                          src: "/assets/bscq2old.jpg"
                        }}
                      />
                        <p className="caption"> <b>Existing:</b> Average 56% correct</p>
                    </div>
                    <div className="span-1">
                      <ImageZoom
                        image={{
                          src: "/assets/bscq2new.jpg",
                          className: "img",
                          style: { width: "100%", margin: "20px auto", borderRadius: '20px' }
                        }}
                        zoomImage={{
                          src: "/assets/bscq2new.jpg"
                        }}
                      />
                        <p className="caption"><b>Proposed:</b> Average 98% correct</p>
                    </div>

                  </div>

                </div>

                  <div className="section-header">
                    <p>Usability Testing</p>
                    <p>010</p>
                  </div>
                  <div className="bsc-2-grid">

                <h2>
                Final results for the <span className="header-serif">usability test</span></h2>
                <p className="project-study-inner">Not only did the absolute value increase, the distribution of guesses also decreased and the correct answer was chosen more often. We found that colour was a huge factor in how well the pictograms performed. Including both colour and pictograms for colour blind accessibility and general understandability.</p>
                </div>
                <div className="bsc-2-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/bscq1res2.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto", borderRadius: '20px' }
                      }}
                      zoomImage={{
                        src: "/assets/bscq1res2.jpg"
                      }}
                    />
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/bscq2res2.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto", borderRadius: '20px' }
                      }}
                      zoomImage={{
                        src: "/assets/bscq2res2.jpg"
                      }}
                    />
                  </div>
                </div>
                </div>

                

              </div>

              <div id="iter" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Trying new things</p>
                    <p>011</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                    <h2>Iterating over <span className="header-serif">pictograms</span></h2>
                    <p className="project-study-inner">I iterated over many different styles to finally land on one. The final set was also iterated over for consistency. We delivered 48px and 24px sized pictograms with 2px stroke width.
                    </p>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/bsciteration1.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/bsciteration1.jpg"
                  }}
                />
                <ImageZoom
                  image={{
                    src: "/assets/bsciterationfinal.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/bsciterationfinal.jpg"
                  }}
                />
              </div>


              <div id="nstar" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Guiding interface</p>
                    <p>012</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid title">
                    <h2> Designing <span className="header-serif">North Star</span> examples</h2>
                    <p className="project-study-inner">North star screens were created to guide the Boston Scientific design team on how to use the new design system. Here are a few examples that use the pictograms I created.
                    </p>
                  </div>

                </div>

                <ImageZoom
                  image={{
                    src: "/assets/bscnorthstar.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/bscnorthstar.jpg"
                  }}
                />
              </div>

              <div className="bigger-bg">
              <div id="reflect" className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>WHAT I LEARNED</p>
                    <p>013</p>
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="bsc-2-grid">
                      <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                    <p className="project-study-inner">
                      Creating a design system from scratch is difficult. We really had to get into the nitty gritty of of the pixels.We spent time learning exactly how use Figma to get the most out of the system.</p>

                  </div>
                  <div className="info">
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Know your tools</h4>
                        <p className="project-study-inner-small">Figma's variant, auto layout and constraint features came in very handy for creating a modular design system. Knowing how to properly use the tool improved the overall outcome.</p>
                      </div>
 
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        <h4>Build for scalability</h4>
                        <p className="project-study-inner-small">It is important to future proof design systems to the best of your ability. Although changes and updates will happen, the system should be robust enough to handle design issues as they come up.</p>
                      </div>
                      </div>
                      </div>
                      </div>
                  </div>
                </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bsc;
