import React from 'react';
import './Process.css';

function Process() {

  return (
    <div className="bigger-bg">
      <div className="fun-box">
    <div className="processBig App top-top">
      <header className="process two-col">
        <div className="span-2">
          <h2>“Design is the <span className="header-serif">intent behind the outcome</span>
            —so be intentional”
          </h2>
          
        </div>
        <div className="span-2">
          <p className="process-para">Each step should be connected to the previous one and provide a reason why the next one is needed. My design process is driven by 4 main things: empathy, mindfulness, neuroscience, aesthetics. </p>
        </div>
      </header>
      <div className="section-header-container">
        <div className="section-header">
          <p>Design principles</p>
          <p>001</p>
        </div>
      
        </div>
      <div className="process d-prin princs">
        <div className="span-2">
          <h6 className="top-number">01</h6>
            <h4>Empathy</h4>
            <p className="project-study-inner-small">I like to build with the user in mind at all times. If I can empathize, then I can advocate for a solution that people actually need.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">02</h6>
            <h4>Mindfulness</h4>
          <p className="project-study-inner-small">Mindfulness and intentionality go hand in hand. At every step of the process, I am mindful that I’m still solving the right problem and ensure all design decisions are deliberate.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">03</h6>
            <h4>Experimentation</h4>
          <p className="project-study-inner-small">Design is testing. I think testing out designs and getting feedback is the most rewarding, validating and humbling part of design and I LIVE for it. Nothing gets my blood pumping like doing usability tests or seeing results of tests in the morning.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">04</h6>
          <h4>Aesthetics</h4>
          <p className="project-study-inner-small">I adore visual design. Creating high fidelity prototypes and designs is my happy place and brings me a lot of joy. I tend to express this love though building design systems.</p>
        </div>
          </div>
      </div>
      </div>
        <div className="case-study-body">
      <div className="section-header-container">
        <div className="section-header">
          <p>Design Process</p>
          <p>002</p>
        </div>
      </div>

      <div className="process d-prin two-col">
        <div className="span-2">
          <h2>“Every project is different but will follow a <span className="header-serif">basic process”</span>
          </h2>

        </div>
        <div className="span-2">
          <p className="process-top-para">These processes are rarely ever linear & steps tend to be repeated multiple times before delivery. </p>
          <p className="process-para">Some will need card sorting and some might have the information architecture already well defined. The nitty gritty method choice tends to differ but I still follow a general process for every project. </p>
        </div>
      </div>

      <div className="process d-prin">
        <div className="span-2">
          <h6 className="top-number">01</h6>
          <h4>Define the Requirements</h4>
          <p className="project-study-inner-small">The first step I take is usually to define the general business needs, requirements for the project, understand the deliverables, timeline and expectations. It's important to always stay on the same page with the rest of the team. At this step, I like to jot down my own hypothesis for what I expect the problem might be. </p>
        </div>
        <div className="span-2">
          <h6 className="top-number">02</h6>
          <h4>Discover the Issues</h4>
          <p className="project-study-inner-small">During this phase, I collect information, talk to people and explore the problem space. I will also look into user reports, and understand why the product came to be the way it is. If user research isn't within the budget or allocated time, I'll try to conduct ad hoc guerilla interviews to avoid designing blindly.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">03</h6>
          <h4>Interpret the Results</h4>
          <p className="project-study-inner-small">The interpretation phase allows me to define the users, their needs and pain points. Personas, journey maps, story boards and problem statements can be defined or redefined. At this stage, I set our goals and the metrics we want to hit for success. This phase is always very insightful.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">04</h6>
          <h4>Ideate over solutions</h4>
          <p className="project-study-inner-small">The ideation phase is a lot of fun for me! I like to bring in developers, other designers, stake holders and customer supports to an ideation session to explore different options. I like to let my mind go wild and come up with as many solutions as I can. I strive for quantity over quality at this stage.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">05</h6>
          <h4>Prototype</h4>
            <p className="project-study-inner-small">In this phase, I will create the interactive prototype. It may be a hi-fi figma prototype or even an interactive website. The level of details and fidelity depends on how far along in the process I am and what I want to test.</p>
        </div>
        <div className="span-2">
          <h6 className="top-number">06</h6>
          <h4>Test, iterate and test again</h4>
          <p className="project-study-inner-small">In this final phase, I will test the prototype with users. Sometimes it will be a quick guerilla test, while other times it may be a full fledged usability test session. The test results will help us understand what needs to be changed and fixed.</p>
        </div>
        </div>
      </div>
      <div className="case-study-body">
      <div className="section-header-container">
        <div className="section-header">
          <p>Design Philosophies</p>
          <p>003</p>
        </div>
      </div>

      <div className="process d-prin two-col">
        <div className="span-2">
          <h2>How I like to <br /> collaborate  &  <br />my <span className="header-serif">work style</span></h2>

        </div>
        <div className="span-2">
          <p className="process-top-para">I like to find information from where ever I can and there is plenty of information to be found.</p>
          <p className="process-para">This means joining supporters as they answer product questions to understand where users have problems or even scouring reviews of the product as well as competing projects to find insights.</p>
        </div>
      </div>

      <div className="process d-prin philo">
        <div className="span-2">
          <h6 className="top-number">01</h6>
          <h4>My Developer Hat</h4>
          <p className="project-study-inner-small">With my background as a front-end engineer, I speak the language of the developers I work with. I think it is important to understand and empathize not only with your users but also with your team. Knowing how to code helps me understand not only the problems the developers on my team faces but I can also explain to them precisely what I want in the design.</p>

        </div>
        <div className="span-2">
          <h6 className="top-number">02</h6>
          <h4>Involve Everyone</h4>
          <p className="project-study-inner-small">Designers should never work in silos. I try to bring in people who I think have the best insights. I talk to developers, product support agents, stake holders, users and user researchers and bring them into the project as early and as often as I can. I enjoy being collaborative and collecting insights from places one might not think to look.</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Process;
