import React from "react";
import CaseInfo from "./CaseInfo";
import "./Potluck.css";
import ImageZoom from "react-medium-image-zoom";
import CaseHeader from "./CaseHeader";

function Potluck() {
  return (
    <div className="App project-study">
      <CaseHeader image="/assets/potluck-top.png" class="potluck" />
      <div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/potluck-top.png"
                title="Potluck"
                desc={<span>A project management tool that aims to <span className="header-serif">simplify</span> project management</span>}
                overview={
                  <p>
                    In the Summer of 2020, I had a UX design internship with
                    Saltwater to work on a project management tool called
                    Potluck. I had a blast working with one other designer and
                    one engineer and learned a lot about product strategy,
                    developer hand off, design systems and design precision. You
                    can checkout Potluck{" "}
                    <a
                      href="https://potluck.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                }
                problem="After a recently UI update, the Monthly Active User (MAU) count had decreased by 30%, the sign up rate was sitting at 20% and there was a 85% drop off after the first interaction. My task was to figure out why."
                outcome="I improved the successful user test tasks rate of the application from 40% to 96% and was offered to stay on as a Product Designer."
                role="Product Designer"
                timeline="May 2020 - Dec 2020"
                class="potluck"
                tools={["Figma", "Vue"]}
                team={["Me", "Pantufla Cuántica", "Eric Smith"]}
              />
            </div>
            <div className="bigger-bg">
              <div className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>End Result</p>
                    <p>004</p>
                  </div>
                </div>

                <video
                  autoplay="autoplay"
                  width="100%"
                  className="overview_vid"
                  style={{ marginTop: "32px" }}
                >
                  <source src="/assets/hero.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="project-study-inner">
              <div id="gac" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Exploring Potluck</p>
                    <p>005</p>
                  </div>
                </div>

                <div style={{ marginTop: "24px" }}>
                  <div className="title bsc-2-grid">
                    <h2>
                      Understanding Potluck's{" "}
                      <span className="header-serif">Info. Architecture</span>
                    </h2>
                    <p className="project-study-inner">
                      I started this project by learning about Potluck. I had
                      meetings with another designer on the team as well as the
                      CTO to learn the history and design decisions. Then I
                      explored Potluck myself and watched FullStory sessions to
                      conduct a heuristic analysis. These steps helped me map
                      out the existing user flow.
                    </p>
                  </div>
                  <ImageZoom
                    image={{
                      src: "/assets/pcurrent.svg",
                      className: "img",
                      style: { width: "100%", margin: "20px auto" },
                    }}
                    zoomImage={{
                      src: "/assets/pcurrent.svg",
                    }}
                  />
                </div>
              </div>

              <div id="qdata" className="mid-width">
                <div className="bigger-bg">
                  <div className="case-study-body">
                    <div className="section-header-container about">
                      <div className="section-header">
                        <p>Checking Google Analytics</p>
                        <p>006</p>
                      </div>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <div className="title bsc-2-grid">
                        <h2>Finding Quantitative Data & <span className="header-serif">metrics</span></h2>
                        <p className="project-study-inner">
                          In order to understand how effective the current
                          onboarding is, I looked at both the Google Analytics
                          and Fullstory sessions to see if there were any
                          dropoffs and where they occurred.
                        </p>
                      </div>
                    </div>

                    <div className="potluck-3-grid text_center sur_stats">
                      <div className="flex_one">
                        <h3 className="stat">85%</h3>
                        <p className="research" width="200px">
                          Of visitors dropped off after the first interaction
                        </p>
                      </div>
                      <div className="flex_one">
                        <h3 className="stat">63%</h3>
                        <p className="research" width="200px">
                          Of visitors dropped off after the 2nd page
                        </p>
                      </div>

                      <div className="flex_one">
                        <h3 className="stat">50%</h3>
                        <p className="research" width="200px">
                          Bounce rate for Potluck
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="case-study-body case-study-body-section">
                  <div className="section-header-container about">
                    <div className="section-header">
                      <p>Exploring Metrics</p>
                      <p>007</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "24px" }}>
                    <div className="bsc-2-grid">
                      <h2>
                        The{" "}
                        <span className="header-serif">
                          Monthly Active User
                        </span>{" "}
                        Count
                      </h2>
                      <p className="project-study-inner">
                        There was a 30% drop in monthly active users in the span
                        of a week after a big change in how the application is
                        used and displayed.
                      </p>
                    </div>
                  </div>

                  <ImageZoom
                    image={{
                      src: "/assets/mau-old.jpg",
                      className: "img",
                      style: {
                        width: "100%",
                        margin: "20px auto",
                        marginTop: "60px",
                      },
                    }}
                    zoomImage={{
                      src: "/assets/mau-old.jpg",
                    }}
                  />
                </div>
              </div>
              <div id="curUse" className="mid-width">
                <div className="case-study-body case-study-body-section">
                  <div className="section-header-container about">
                    <div className="section-header">
                      <p>Testing Potluck</p>
                      <p>009</p>
                    </div>
                  </div>

                  <div style={{ marginTop: "24px" }}>
                    <div className="title bsc-2-grid">
                      <h2>
                        Evaluating Potluck's{" "}
                        <span className="header-serif">current experience</span>
                      </h2>
                      <p className="project-study-inner">
                        After seeing these metrics, I conducted evaluation
                        research with 5 individuals to assess product issues. I
                        recruited 1 tech lead, 1 startup CEO, 1 PM and 2 devs.
                        Given the issues, I decided to start by watching them
                        explore Potluck first; then nudging them to find things.
                        Here are some questions I had asked.
                      </p>
                    </div>

                    <div className="eval_research">
                      <li>What does this product do?</li>
                      <li>What is a template?</li>
                      <li>What is your daily project routine?</li>
                      <li>Can you add a task?</li>
                      <li>Can you add a due date?</li>
                      <li>Can you assign a task to yourself?</li>
                      <li>Does the landing page represent your experience?</li>
                      <li>What is the product used for?</li>
                    </div>
                  </div>
                </div>

                <div className="bigger-bg">
                  <div className="case-study-body">
                    <div className="section-header-container about">
                      <div className="section-header">
                        <p>Quantifying Problems</p>
                        <p>010</p>
                      </div>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <div className="bsc-2-grid title">
                        <h2>Usability Test <br/><span className="header-serif">Results & Findings</span></h2>
                        <p className="project-study-inner">The results showed a glaring issue. With only 40% of the tasks being completed successfully, I knew Potluck was difficult to understand and navigate without external help. </p>
                      </div>

                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <th></th>
                              <th>User#1</th>
                              <th>User#2</th>
                              <th>User#3</th>
                              <th>User#4</th>
                              <th>User#5</th>
                            </tr>
                            <tr>
                              <td>Add new task</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                            </tr>
                            <tr>
                              <td>Assign yourself task</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                            </tr>
                            <tr>
                              <td>Open sidebar?</td>
                              <td className="red">&times;</td>
                              <td className="green">✓</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                            </tr>
                            <tr>
                              <td>Add subtasks</td>
                              <td className="green">✓</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                            </tr>
                            <tr>
                              <td>Clear what to do?</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                            </tr>
                            <tr>
                              <td>Understood templates?</td>
                              <td className="red">&times;</td>
                              <td className="red">&times;</td>
                              <td className="green">✓</td>
                              <td className="red">&times;</td>
                              <td className="green">✓</td>
                            </tr>
                            <tr>
                              <td>Add due date</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                              <td className="green">✓</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="chall" className="mid-width section-spacer">
                <div className="case-study-body">
                  <div className="section-header-container about">
                    <div className="section-header">
                      <p>Insights</p>
                      <p>011</p>
                    </div>
                  </div>

                  <div style={{ marginTop: "24px" }}>
                    <div className="title bsc-2-grid">
                      <h2>Major <span className="header-serif">Findings &  Uncovered Insights</span></h2>
                      <p className="project-study-inner">Based on the usability test and follow-up interviews, these were the most pressing issues that needed to be addressed.</p>
                    </div>
                    <div className="bsc-2-grid">
                      <div className="span-1">
                        <h6 className="top-number">01</h6>
                        <h4>Discoverability</h4>
                        <p className="project-study-inner-small">
                          A few users had a hard time finding features. The
                          project interface provided no tutorials or examples
                          for how to get started. There were also usability
                          issues that led to people not know certain features
                          existed.
                        </p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">02</h6>
                        <h4>How to show progress</h4>
                        <p className="project-study-inner-small">
                          A major issue expressed by every tester was the lack
                          of collaboration and progress. For managers and
                          leaders, it was important to see how close the team
                          was to completion and any blockers between issues.
                        </p>
                      </div>
                      <div className="span-1">
                        <h6 className="top-number">03</h6>
                        <h4>How to show value</h4>
                        <p className="project-study-inner-small">
                          Users expressed the landing page didn't accurately
                          present what the product did and didn't find the value
                          in it. They didn't know why or if they'd switch from
                          their current project management tool.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bigger-bg">
                  <div className="case-study-body">
                    <div style={{ marginTop: "24px" }}>
                      <div className="potluck title">
                        <h2 style={{ lineHeight: "150%" }}>
                          How can we make features more{" "}
                          <span className="header-serif">discoverable</span>,
                          impart a sense of{" "}
                          <span className="header-serif">value</span>,{" "}
                          <span className="header-serif">collaboration</span> &
                          project{" "}
                          <span className="header-serif">progression</span>?
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ideation"
                className="mid-width case-study-body section-spacer"
              >
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Exploring Possibilities</p>
                    <p>013</p>
                  </div>
                </div>

                <div style={{ marginTop: "24px" }}>
                  <div className="title bsc-2-grid">
                    <h2>
                      Ideation: Targetting{" "}
                      <span className="header-serif">Quantity</span> over
                      Quality
                    </h2>
                    <p className="project-study-inner">
                      I found many issues while doing research for this project
                      but I narrowed it down into the main issues that would
                      solve the problem. By boiling down my research into these
                      goals, it helps me make sure my later design decision are
                      grounded in my user research and actually solve the
                      problem.
                    </p>
                  </div>

                  <div className="potluck-weird-grid">
                    <div style={{ fontWeight: 900 }}>Issue</div>
                    <div style={{ fontWeight: 900 }}>Solution</div>
                    <div
                      className="no-right-border"
                      style={{ fontWeight: 900 }}
                    >
                      Notes
                    </div>
                    <div className="pl-row-2">How to show value</div>
                    <div>Redo the landing page</div>
                    <div className="no-right-border">
                      The landing page should express the power and the
                      simplicity of the product.
                    </div>
                    <div>Cut down the number of onboarding steps</div>
                    <div className="no-right-border">
                      We need to reduce the 63% drop off. Maybe by bringing them
                      right into the product, we can show value.
                    </div>
                    <div className="pl-row-3">How to show value</div>
                    <div>Gantt Chart</div>
                    <div className="no-right-border">
                      Show timelines visually. It could be hard to not overload
                      the user.
                    </div>
                    <div>Actively Working + task status</div>
                    <div className="no-right-border">
                      Users can set status to show progress. It has Users may or
                      may not remember to set their status. We can't over
                      complicate it.
                    </div>
                    <div>Sorting by due date, assigned, status</div>
                    <div className="no-right-border">
                      Users can see the tasks based on priority. But this may
                      clutter the UI & reduce simplicity.
                    </div>
                    <div className="pl-row-3 no-bottom-border">
                      Discoverability
                    </div>
                    <div>Add sample project</div>
                    <div className="no-right-border">
                      Users can see the features but there may be an issue with
                      clearing the tasks.
                    </div>
                    <div>
                      Add menu icon to open sidebar & have it pre-opened
                    </div>
                    <div className="no-right-border">
                      Users didn't know how to open the side bar.
                    </div>
                    <div className="no-bottom-border">
                      Bring out important actions from the submenu
                    </div>
                    <div className="no-bottom-border no-right-border">
                      The menu was too crowded. We also need to be careful which
                      actions to bring out to avoid crowding.
                    </div>
                  </div>
                </div>
              </div>

              <div className="bigger-bg">
                <div
                  id="iteration"
                  className="mid-width case-study-body case-study-body-section"
                >
                  <div className="section-header-container about">
                    <div className="section-header">
                      <p>Issue #1: Showing Value</p>
                      <p>014</p>
                    </div>
                  </div>

                  <div style={{ marginTop: "24px" }}>
                    <div className="title bsc-2-grid">
                      <h2><span className="header-serif">Reducing Steps</span> to <br/>show value sooner</h2>
                      <p className="project-study-inner">
                        Since there was a 63% dropoff after the 2nd interaction,
                        I wanted to drive value as soon as possible. I reduced
                        the onboarding flow from 5 steps down to 3 steps.
                      </p>
                    </div>
                  </div>

                  <ImageZoom
                    image={{
                      src: "/assets/pflownew.svg",
                      className: "img",
                      style: { width: "100%", margin: "20px auto" },
                    }}
                    zoomImage={{
                      src: "/assets/pflownew.svg",
                    }}
                  />
                </div>
                </div>
                <div>

                <div className="mid-width section-spacer case-study-body">
                  <div className="section-header">
                    <p>Working With Others</p>
                    <p>015</p>
                  </div>
                
                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid">
                      <h2>Iteration & <span className="header-serif">Collaboration</span></h2>
                    <p className="project-study-inner">
                      I started with low-fi prototypes and tested them quickly
                      with users to hash out how I thought the flow should work.
                      Once I had worked out the high level overview, another
                      designer on the team took over the details of the design.
                      We iterated over the details together and she designed
                      them. The following are the low to mid level design
                      iterations I went through personally.
                    </p>
                  </div>
                </div>

                <ImageZoom
                  image={{
                    src: "/assets/piterations.png",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" },
                  }}
                  zoomImage={{
                    src: "/assets/piterations.png",
                  }}
                />
                </div>

                <div className="section-header-container about case-study-body section-spacer">
                  <div className="section-header">
                    <p>Issue #2: How to show progress</p>
                    <p>016</p>
                  </div>
                

                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid">
                      <h2>Actively Working & <span className="header-serif">Project Status</span></h2>
                    <p className="project-study-inner">
                      Since there was a 63% dropoff after the 2nd interaction, I
                      wanted to drive value as soon as possible. I reduced the
                      onboarding flow from 5 steps down to 3 steps.
                    </p>
                  </div>
                </div>

                <div className="bsc-2-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/pact1.png",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/pact1.png",
                      }}
                    />
                    <h4>Low-Fidelity</h4>
                    <p className="project-study-inner-small">
                      We came up with many ideas. We'd pitch them and let the
                      others critique them. I guerilla tested the best ideas for
                      usability.
                    </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/pact12.png",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/pact12.png",
                      }}
                    />
                    <h4>High-Fidelity</h4>
                    <p className="project-study-inner-small">
                      We ended up picking the status setting on the task check.
                      It was the simplest solution that performed the best in
                      guerilla tests.
                    </p>
                  </div>
                </div>
                </div>
                <div className="bigger-bg">
                <div className="section-header-container about case-study-body">
                  <div className="section-header">
                    <p>Issue #1: Showing Value</p>
                    <p>017</p>
                  </div>
                

                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid">
                        <h2>Previewing <span className="header-serif">Templates</span> before Selection</h2>
                    <p className="project-study-inner">
                      Templates were harder for people to understand partially
                      because they didn't know what the product did and because
                      they couldn't see previews. These are the following
                      iterations we tried. In the end, we chose the 3rd option
                      because it was the clearest and only available when the
                      user looked for it. It also performed better in user
                      tests.
                    </p>
                  </div>
                </div>

                <div className="potluck-3-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/temp1.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/temp1.jpg",
                      }}
                    />
                    <h4>1. Popup preview</h4>
                    <p className="project-study-inner-small">
                      Shows up before project is fully created. Shows preview
                      but it's not as important
                    </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/temp4.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/temp4.jpg",
                      }}
                    />
                    <h4>2. Hover Preview</h4>
                    <p className="project-study-inner-small">
                      Shows up before project is fully created. Shows preview
                      but it's not as important. The preview feels like a
                      temporary experience. Seeing previews over takes the
                      screen.
                    </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/temp3.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/temp3.jpg",
                      }}
                    />
                    <h4>3. Dedicated previews</h4>
                    <p className="project-study-inner-small">
                      Popup after the project is created with the preview taking
                      more attention.
                    </p>
                  </div>
                </div>
                </div>
                </div>
                <div className="section-header-container about case-study-body section-spacer">
                  <div className="section-header">
                    <p>Issue #1: Showing Value</p>
                    <p>018</p>
                  </div>

                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid">
                      <h2>Templates: <span className="header-serif">High <br/>Fidelity</span> Mockups </h2>
                    <p className="project-study-inner">
                      Templates were harder for people to understand partially
                      because they didn't know what the product did and because
                      they couldn't see previews. These are the following
                      iterations we tried. In the end, we chose the 3rd option
                      because it was the clearest and only available when the
                      user looked for it. It also performed better in user
                      tests.
                    </p>
                  </div>
                </div>

                <div className="bsc-2-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/temphi1.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/temphi1.jpg",
                      }}
                    />
                    <h4>Light Mode</h4>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/temphi2.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/temphi2.jpg",
                      }}
                    />
                    <h4>Dark Mode</h4>
                  </div>
                </div>
                </div>

                <div className="bigger-bg">
                <div className="section-header-container about case-study-body">
                  <div className="section-header">
                    <p>Issue #3: Discoverability</p>
                    <p>019</p>
                  </div>

                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid">
                    <h2><span className="header-serif">Sample Project</span> as an Interactive Turtorial</h2>
                    <p className="project-study-inner">
                      One of the complaints we got was that users didn't know
                      what else Potluck could offer. I added a sample project
                      that shows tasks and gives users a way see what Potluck
                      could do. We ran into a issue with how to show templates
                      and let users create a project. I conducted a few guerilla
                      tests along the way to find better solutions.
                    </p>
                  </div>
                </div>

                <div className="potluck-3-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/proj3.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/proj3.jpg",
                      }}
                    />
                    <h4>1. Buttons at the top</h4>
                    <p className="project-study-inner-small">
                      I tried having the button on the top but users would skip
                      the tutorial and click the buttons. We knew it would have
                      to come after they see the sample project.
                    </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/proj1.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/proj1.jpg",
                      }}
                    />
                    <h4>2. One bottom button</h4>
                    <p className="project-study-inner-small">
                      I tried having one button for templates but it that meant
                      people would have to delete all tasks if they just wanted
                      to create a project.
                    </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/proj4.jpg",
                        className: "img",
                        style: { width: "100%", margin: "20px auto" },
                      }}
                      zoomImage={{
                        src: "/assets/proj4.jpg",
                      }}
                    />
                    <h4>2. Two bottom buttons</h4>
                    <p className="project-study-inner-small">
                      This was the best of both worlds. It follows a logical
                      narrative and offers different options for how to build a
                      project. We ended up going with this version.
                    </p>
                  </div>
                </div>
                  </div>

                </div>

                <div className="section-header-container about case-study-body section-spacer">
                  <div className="section-header">
                    <p>End Result</p>
                    <p>020</p>
                  </div>
                
                <div style={{ marginTop: "24px" }}>
                </div>

                <video
                  autoplay="autoplay"
                  width="100%"
                  className="overview_vid"
                >
                  <source src="/assets/hero.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              </div>

              <div className="bigger-bg">
                <div id="landing" className="mid-width">
                  <div className="case-study-body">
                    <div className="section-header-container about">
                      <div className="section-header">
                        <p>Issue #1: Showing Value</p>
                        <p>021</p>
                      </div>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <div className="bsc-2-grid">
                        <h2>A New Potluck <br/><span className="header-serif">Landing Page</span></h2>
                        <p className="project-study-inner">
                          With feedback I got from testing the existing site, I
                          redesigned the landing page by making it more company
                          focused, adding social proof. Pantufla created the
                          amazing animation to make it more visually
                          interesting.
                        </p>
                      </div>
                    </div>
                  </div>

                  <ImageZoom
                    image={{
                      src: "/assets/landingpage.jpg",
                      className: "img",
                      style: { width: "100%", margin: "20px auto" },
                    }}
                    zoomImage={{
                      src: "/assets/landingpage.jpg",
                    }}
                  />
                </div>
              </div>
              <div id="results" className="mid-width case-study-body section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Testing again</p>
                    <p>022</p>
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <div className="bsc-2-grid title">
                    <h2>Another Round of <span className="header-serif">Usability Tests </span></h2>
                    <p className="project-study-inner">
                      Even though each component has been tested for usability,
                      I wanted to make sure the full onboarding experience
                      worked and made sense. I conducted a full usability test
                      with 7 people. 3 of them had done the test before (to see
                      if the improvements helped them). Here are the results.
                    </p>
                  </div>

                  <div className="table">
                    <table>
                      <tbody>
                        <tr>
                          <th></th>
                          <th>User#1</th>
                          <th>User#2</th>
                          <th>User#3</th>
                          <th>User#4</th>
                          <th>User#5</th>
                        </tr>
                        <tr>
                          <td>Add new task</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Assign yourself task</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Open sidebar?</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Add subtasks</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="red">&times;</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Clear what to do?</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Understood templates?</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="red">&times;</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                        <tr>
                          <td>Add due date</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                          <td className="green">✓</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="project-study-inner">
                    I am so happy with the results. The users that read the
                    sample project were able to understand how to use Potluck
                    and saw the value. Users who didn't read the sample project
                    were still able to figure it out on their own. There were
                    significant improvements on all tasks. Some went from 0%
                    success to 100%.
                  </p>

                  <p className="project-study-inner">
                    And finally, after all of our hard work, we saw the MAU
                    shoot up.
                  </p>

                  <ImageZoom
                    image={{
                      src: "/assets/potluck-end.jpg",
                      className: "img",
                      style: { width: "100%", margin: "20px auto" },
                    }}
                    zoomImage={{
                      src: "/assets/potluck-end.jpg",
                    }}
                  />
                  <p className="project-study-inner">
                    In addition, I wrote a blog post about my experience
                    building a design system in Figma for potluck. It has
                    received over 20K reads. Please feel free to read it{" "}
                    <a
                      href="https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>

                  <ImageZoom
                    image={{
                      src: "/assets/potluck-designsys.png",
                      className: "img",
                      style: { width: "100%", margin: "20px auto" },
                    }}
                    zoomImage={{
                      src: "/assets/potluck-designsys.png",
                    }}
                  />
                </div>
              </div>
              <div className="bigger-bg">
                <div id="reflect" className="mid-width case-study-body">
                  <div className="section-header-container about">
                    <div className="section-header">
                      <p>What I Learned</p>
                      <p>023</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "24px" }}>
                    <div className="bsc-2-grid">
                      <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                      <p className="project-study-inner">
                        Creating a design system from scratch is difficult. We
                        really had to get into the nitty gritty of of the
                        pixels.We spent time learning exactly how use Figma to
                        get the most out of the system.
                      </p>
                    </div>
                    <div className="info">
                      <div className="bsc-2-grid">
                        <div className="span-1">
                          <h6 className="top-number">01</h6>
                          <h4>Projects get messy</h4>
                          <p className="project-study-inner-small">
                            Lots of changes can make a project very messy. They
                            can also impact the measurement of final metrics.
                            Each change should be paired with a metric we intend
                            to improve. It's important to organize files for dev
                            hand off. They should have easy access to assets and
                            know exactly what changes they have to make. Follow
                            (or make) the design system.
                          </p>
                        </div>
                        <div className="span-1">
                          <h6 className="top-number">02</h6>
                          <h4>Be precise in your designs</h4>
                          <p className="project-study-inner-small">
                            Be wary of padding and margins. Developers won't
                            know what values to set if you don't. Take the guess
                            work out of it and make your designs as exact as
                            possible. Don't make your devs designers unless they
                            want it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Potluck;
