import FeatherIcon from 'feather-icons-react'

export const renderIcon = (icon) => {
  let color = '#fff'

    return (
      <div className="icon-outer">
        <FeatherIcon icon={icon} stroke={color} className="icon" />
      </div>
    )
  }