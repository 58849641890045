import React from 'react';
import './Freelance.css';
import { renderIcon } from './projects/Icon'



const FREELANCE = [
  {
    id: 'OctoShop',
    link: 'https://chrome.google.com/webstore/detail/octoshop-in-stock-alerts/ahajkaoongodcmoohkcceoklokapadfh',
    image: './assets/octoshop.svg',
    date: 'Apr 5th 2021',
    number: '01',
    title: 'OctoShop',
    desc: 'I helped build OctoShop; a web extension that notifies user when items are back in stock. I helped conduct user research, build wireframes, prototype, user test and developed the front-end. The extension has 100,000+ downloads'
  },
  {
    id: 'InStok',
    link: 'https://www.instok.org/',
    image: './assets/instok.svg',
    number: '02',
    title: 'InStok',
    desc: "InStok lets users find items that are in stock near by and has received 800k+ visits. I redesigned the landing page and improved the user-experience of searching for products."
  },
  {
    id: 'Datafold',
    link: 'https://www.datafold.com/',
    image: './assets/datafold.png',
    number: '03',
    title: 'Datafold',
    desc: 'Datafold is a Y Combinator 2020 company that helps companies maintain data quality and integrity.I was contracted for designing a landing page for them'
  },
  {
    id: 'layer',
    link: 'https://layerci.com/',
    image: './assets/layerci.png',
    number: '04',
    title: 'LayerCI',
    desc: 'LayerCI is a Y Combinator 2020 company thats makes manual and visual QA easier. I redesigned their landing page which helped them land the YC interview. Investors in the company mentioned the site made them look more legitimate.'
  },
  {
    id: 'RallyVideo',
    link: 'https://rally.video/',
    image: './assets/rally.png',
    number: '05',
    title: 'Rally Video',
    desc: 'Rally Video is a Y Combinator 2020 company that aims to make online events more social and interactive. I redesigned their landing page and developed it in Webflow.'
  },
  {
    id: 'rucker',
    link: 'https://www.rpvc.com/',
    image: './assets/rucker.svg',
    number: '06',
    title: 'Ruckerpark VC',
    desc: 'Ruckerpark is a VC firm that wanted a simpler landing page. I designed and developed it using Webflow.'
  },
  {
    id: 'covidcoop',
    link: 'https://covidcoop.com/',
    image: './assets/covidcoop.svg',
    number: '07',
    title: 'Covid Co-Op',
    desc: 'Covid Coops aims to help Covid causes in America while still supporting small and local businesses. I designed and developed it using reactJS. This project was one of the winners of the Global Covid Hackathon'
  },


]
function Freelance() {

  const renderArticle = () => {
    return FREELANCE.map(item => {
      return (
        <a href={item.link} rel="noreferrer" target="_blank" key={item.id} className="article">
          <div className="article-info">
            <div className="external">
              <h6 className="top-number">{item.number}</h6>
              <div>{renderIcon('external-link')}</div>
            </div>
            <h4 style={{ marginTop: '20px' }}>{item.title}</h4>
            <p className="project-study-inner-small">{item.desc}</p>
          </div>
        </a>
      )
    })

  }

  return (
    <div className="fun-box bigger-bg">
    <div className="processBig top-top App">
      <header className="process two-col">
        <div className="span-2">
          <h2><span className="header-serif">Freelance design work</span>, contracts & projects
          </h2>
        </div>
        <div className="span-2">
          
            <p className="process-para">I was hired by various companies to create landing pages, interfaces and/or various design elements. I always have a blast meeting new teams, understanding their processes and building solutions that work for them. The following projects don't have a case study. </p>
        </div>
      </header>

      <div className="section-header-container">
        <div className="section-header">
          <p>Work</p>
          <p>001</p>
        </div>
      </div>

      <div className="articles">
        {renderArticle()}
      </div>
    </div>
    </div>
  );
}

export default Freelance;
