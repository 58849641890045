import React from 'react';
import CaseInfo from './CaseInfo';
import './ROM.css';
import ImageZoom from 'react-medium-image-zoom';
import CaseHeader from './CaseHeader';
import Slider from "react-slick";
import './Slick.css';
// import Nav from '../Nav';




function ROM() {
  const lofi = {
    customPaging: function (i) {
      return (
        <span className="cara_img">
          <img src={`/assets/romdeck-${i}.jpg`} alt="Low Fidelity Issues and Solutions" width="100%" />
        </span>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="App project-study ROM-case">
      {/* <Nav
        items={[
          "overview",
          "result",
          "context",
          "research",
          "ideation",
          "mid",
          "test",
          "brand",
          "dessys",
          "VR",
          "reflection"
        ]}>
        <li><a href="#overview">Overview</a></li>
        <li><a href="#result">Result</a></li>
        <li><a href="#context">Context</a></li>
        <li><a href="#research">Research</a></li>
        <li><a href="#ideation">Ideation</a></li>
        <li><a href="#mid">Mid-Fi</a></li>
        <li><a href="#test">Usability Test</a></li>
        <li><a href="#brand">Brand</a></li>
        <li><a href="#dessys">Design System</a></li>
        <li><a href="#VR">VR Experience</a></li>
        <li><a href="#reflection">Reflection</a></li>
      </Nav> */}

      <CaseHeader
        image="/assets/HomeROM.jpg"
        class="ROM"
      />
      <div>
        <div className="case-study-navi" id="nav">
        </div>
        <div className="case-study-content" id="case-study">
          <div className="case-study-content-inner">
            <div id="overview" className="case-study-body">
              <CaseInfo
                image="/assets/ins.png"
                title="ROM"
                desc={<span>A mobile app and AR/VR experience to make the Royal Ontario Museum <span className="header-serif">more engaging</span></span>}
                overview={<span>I've always been interested in emerging technology. As a way to improve my skills, I decided to learn VR/AR prototyping and design. I made this experience as a personal project to improve these skills and get better as a designer. Current museum experiences are stagnant and many people find they lack engagement. Living Museum brings the past alive through interactive and immersive stories. Users can see how artifacts are connected, their stories, fun facts, and their histories. <b>This project is not affiliated with the Royal Ontario Museum in Toronto. It is just for practice.</b> </span>}
                problem="How might we make museum experiences more immersive, exciting, and story-driven?"
                  outcome="I created a web, mobile AR and Unity VR experience that makes digital museum visits more fun through scavenger hunts, personal collection curation and immersive exhibits"
                role="Product Designer"
                timeline="Jan 2022 - April 2022"
                tools={['Figma', 'Unity', 'Google Suite']}
                class="ROM"
              />
            </div>

            <div className="bigger-bg" id="result">
              <div className="mid-width case-study-body">
                <div className="section-header-container about no-bottom">
                  <div className="section-header">
                    <p>End Result</p>
                    <p>004</p>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="title">
                    <div className="bsc-2-grid">
                      <h2>Final application <span className="header-serif">designs & solutions</span></h2>

                      <p className="project-study-inner">Over the course of 4 months, I worked on a multimodal experience for museum experiences. This included building desktop, mobile, AR, VR applications and a design system that supports all it. This project aims to make museum experience <b>more immersive & interactive.</b> </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rom-final-reel">

              <div className="grid-4">
                <div className="span-3">
                  <video autoPlay loop muted width="100%">
                    <source src="/assets/rom1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                  <div className="rom-reel-info">
                  <div>
                      <h2>Interactive <span className="header-serif">Scavenger Hunts</span></h2>
                    </div>
                  <div>
                      <h6>◀ Desktop Experience</h6>

                      <p>79% of survey respondents wished museums were more engaging. Users engage with museum artifacts through an interactive scavenger hunt experience. Online exhibitions can provide users with the option to dig deeper into the collection while also learning fun facts.</p>
                    </div>
                  </div>
              </div>
 
              </div>
              <div className="rom-final-reel">

              <div className="grid-4">

                  <div className="rom-reel-info">
                  <div>
                      <h2>Robust <span className="header-serif">filtering system</span></h2>
                    </div>
                  <div>
                      <h6>Desktop Experience ▶</h6>

                      <p>Giving users a robust filtering system helps them find exact what they need. Some local museum goers already know the exhibits. They would rather find specific items they enjoy.</p>
                    </div>
                  </div>
                  <div className="span-3">
                    <video autoPlay loop muted width="100%">
                      <source src="/assets/rom2.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                  </div>
              </div>
 
              </div>
              <div className="rom-final-reel">

                <div className="grid-4">
                  <div className="span-3">
                    <video autoPlay loop muted width="100%">
                      <source src="/assets/rom3.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                  </div>
                  <div className="rom-reel-info">
                    <div>
                      <h2>Personal <span className="header-serif">collections & exhibits</span></h2>
                    </div>
                    <div>
                      <h6>◀ Desktop Experience</h6>

                      <p>In order to preserve artifacts,museums maintain a barrier between the person and the artifact, leaving some museum goers feeling disconnected. This application allows users to create personal connection with artifacts by building collections for themselves or sharing them with others. </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="rom-final-reel">
                <div className="grid-4">
                <div/>
                  <div className="rom-reel-info">
                    <div>
                      <h6>Mobile & AR Experience ▶</h6>

                    </div>
                    <div>
                      <h2>Artifact <span className="header-serif">Extension</span></h2>
                      <p>Allowing art to come alive and spill into the real world through this mobile AR experience. Users are immersed into the world of the artifact, leaving them with a deeper understanding of the piece.</p>
                    </div>
                  </div>
                  <div className="span-1">
                  <div>
                    <video autoPlay loop muted width="100%">
                      <source src="/assets/rom5.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                    </div>
                    <div></div>
                  </div>
                </div>

              </div>
              <div className="rom-final-reel">
                <div className="bsc-2-grid">
                  <div>
                    <h6>AR Experience ▼</h6>
                    <h2>Artifact <span className="header-serif">Extender</span></h2>
                  </div>
                  <div>
                    <p>On a more educational note, I also created an AR experience that allows users to learn more about artifacts in a fun and interactive way. It allows museums to display content they may not otherwise be able. </p>
                  </div>
                </div>
                <video autoPlay loop muted width="100%" style={{ marginTop: '32px' }}>
                  <source src="/assets/AR.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                    </video>

              </div>
            </div>
            <div id="context" className="mid-width case-study-body case-study-body-section section-spacer">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Setting Expectation</p>
                  <p>005</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>What this project is and what <span className="header-serif">to expect</span></h2>
                <p className="project-study-inner">My goal with this project was to practice my visual design, interaction design and prototyping skills while also learning AR/VR design. I conducted user research and a usability testing session.</p>
              </div>
              <div className="info">
                <div className="bsc-2-grid">
                  <div className="span-1">
                    <h6 className="top-number">01</h6>
                    <h4>Personal Project</h4>
                    <p className="project-study-inner-small">This is a personal project to develop and improve my skills. There are no metrics or outcomes beyond my personal growth. </p>
                  </div>
                  <div className="span-1">
                    <h6 className="top-number">02</h6>

                    <h4>Practicing Visual & Interaction Design</h4>
                    <p className="project-study-inner-small">I focused on high visual polish, consistency, building a design system and a design language for this project.  </p>
                  </div>
                  <div className="span-1">
                    <h6 className="top-number">03</h6>

                    <h4>Trying new things</h4>
                    <p className="project-study-inner-small">I also wanted to work on this project to try new things. My goal was to getting a basic understanding of building AR and VR experiences.</p>
                  </div>
                </div>
              </div>
              </div>


            <div id="research" className="bigger-bg">
              <div  className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Uncovering Issues</p>
                    <p>006</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>User research <span className="header-serif">— findings & insights</span></h2>
                  <p className="project-study-inner">To get a baseline understanding of the public space, I conducted <b>2 semi-structured interviews</b>. Additionally, <b>59 individuals completed a survey</b>. Here are the findings.</p>
                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">97%</h6>
                      <h4>Of respondents did not engage with online museum content</h4>
                      <p className="project-study-inner-small">Many of them mentioned it was because they didn't know this type of content existed. One participant mentions that "it's not interesting if not in person. I could look at pictures and read anything anywhere if I wanted to. It's just static information."</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">79%</h6>

                      <h4>Of respondents wish museums were more engaging</h4>
                      <p className="project-study-inner-small">Both participants agreed that existing experiences in museums are not interactive. Instead, there
are heavy text descriptions that they don't engage with. One person
mentions they don't like that museums impart a "...sense of sacredness requiring silence and
reverence."</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">66%</h6>

                      <h4>Of respondents prefer visiting museums with other people</h4>
                      <p className="project-study-inner-small">
This is primarily since others can act as impromptu tour guides. Participant one says that it's "...good to go with a diverse group of people because some people know things that others don't, so we all kind of stop and share our own anecdotes."</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              


            <div id="ideation" className="mid-width case-study-body case-study-body-section section-spacer no-bottom">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Ideation</p>
                  <p>007</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Low fidelity <span className="header-serif">sketches, ideas & flows</span></h2>
                <p className="project-study-inner">I ideated over potential solutions and user flows. I used sketches to solidify ideas and draw out how I wanted the interactions to work.</p>
              </div>

            </div>
            <ImageZoom
              image={{
                src: '/assets/rom-sketches.jpg',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/rom-sketches.jpg',
              }}
            />



            <div id="mid" className="bigger-bg" style={{paddingBottom: '0px'}}>
              <div id="skill" className="mid-width case-study-body no-bottom">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Mid-Fi</p>
                    <p>008</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Medium fidelity <span className="header-serif"> designs & wireframes</span></h2>
                  <p className="project-study-inner">After some initial sketches, I created mid-fi wireframes to build out interactions and flows to test with users. The following shows the mobile version of the application. </p>
                </div>
              </div>
              <ImageZoom
                image={{
                  src: '/assets/rom-mid-fi.jpg',
                  className: 'img',
                  style: { width: '100%', marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/rom-mid-fi.jpg',
                }}
              />

            </div>


              <div id="test" className="mid-width case-study-body case-study-body-section section-spacer">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Testing & Iterations</p>
                    <p>009</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Usability testing, <span className="header-serif"> findings & iterations</span></h2>
                <p className="project-study-inner">I conducted a small usability test with 4 individuals. I was looking to test clarity, completion and comprehension of the different features I made. </p>
                </div>

              <div className="info">
                <div className="bsc-2-grid">
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-1-old.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-1-old.jpg"
                      }}

                    />
                    <h4 className="before-iter">Before</h4>
                    <p className="project-study-inner-small">The 'view' button on collections was not clear. Some testers thought it was a view count and not a button at all.</p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-1-new.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-1-new.jpg"
                      }}

                    />
                    <h4 className="after-iter">After</h4>
                    <p className="project-study-inner-small">To address this, I have updated the text from simply “View” to “View Collection.”
 </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-2-old.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-2-old.jpg"
                      }}

                    />
                    <h4 className="before-iter">Before</h4>
                    <p className="project-study-inner-small">The term "timeline" was confusing to some testers. They thought the button would show them a history of items they have looked at. </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-2-new.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-2-new.jpg"
                      }}

                    />
                    <h4 className="after-iter">After</h4>
                    <p className="project-study-inner-small">To address this, I have updated the text from "timeline" to “Artifact History.” </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-3-old.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-3-old.jpg"
                      }}

                    />
                    <h4 className="before-iter">Before</h4>
                    <p className="project-study-inner-small">Users expressed a way to add tags to collections since searching for them otherwise would be too vague. </p>
                  </div>
                  <div className="span-1">
                    <ImageZoom
                      image={{
                        src: "/assets/rom-3-new.jpg",
                        className: "img",
                        style: { width: "100%", marginTop: "20px", marginBottom: "-16px" }
                      }}
                      zoomImage={{
                        src: "/assets/rom-3-new.jpg"
                      }}

                    />
                    <h4 className="after-iter">After</h4>
                    <p className="project-study-inner-small">To address this, I added a keyword input on the create collection screen to allow users to add keywords. For brevity’s sake, keywords will be accepted using comma separations but chips could work.
</p>
                  </div>
                </div>
                </div>
              </div>

            <div id="brand">
            <div className="bigger-bg">
            <div className="mid-width case-study-body">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Branding</p>
                  <p>010</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                  <h2>Building a brand <span className="header-serif">identity kit</span></h2>
                <p className="project-study-inner">To support the rest of the design, I created a brand guideline kit. It contains tone of voice, logos, colors, type, iconography, imagery and product vision.</p>
              </div>
                <div className="slider-cont">

                <Slider {...lofi} >
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-0.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-0.jpg',
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-1.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-1.jpg',
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-2.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-2.jpg',
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-3.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-3.jpg',
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-4.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-4.jpg',
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      image={{
                        src: '/assets/romdeck-5.jpg',
                        className: 'img',
                        style: { width: '100%', marginTop: 50 }
                      }}
                      zoomImage={{
                        src: '/assets/romdeck-5.jpg',
                      }}
                    />
                  </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-6.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-6.jpg',
                        }}
                      />
                    </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-7.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-7.jpg',
                        }}
                      />
                    </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-8.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-8.jpg',
                        }}
                      />
                    </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-9.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-9.jpg',
                        }}
                      />
                    </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-10.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-10.jpg',
                        }}
                      />
                    </div>
                    <div>
                      <ImageZoom
                        image={{
                          src: '/assets/romdeck-11.jpg',
                          className: 'img',
                          style: { width: '100%', marginTop: 50 }
                        }}
                        zoomImage={{
                          src: '/assets/romdeck-11.jpg',
                        }}
                      />
                    </div>

                </Slider>
                </div>
            </div>
            </div>

            <div className="mid-width case-study-body case-study-body-section section-spacer">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>A New Logo</p>
                  <p>011</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Creating a brand <br/>new <span className="header-serif">logo set</span></h2>
                <p className="project-study-inner">To support the feeling of regality, tradition and intelligence with a hint of modernity of the new design language, I created a new logo. I added a full, partial and logo mark version for scalability.</p>
              </div>
              <div className="grid-4">
                <ImageZoom
                  image={{
                    src: '/assets/rom-logo-1.jpg',
                    className: 'img span-2',
                    style: { width: '100%', marginTop: 50 }
                  }}
                  zoomImage={{
                    src: '/assets/rom-logo-1.jpg',
                  }}
                />
                <ImageZoom
                  image={{
                    src: '/assets/rom-logo-2.jpg',
                    className: 'img span-2',
                    style: { width: '100%', marginTop: 50 }
                  }}
                  zoomImage={{
                    src: '/assets/rom-logo-2.jpg',
                  }}
                />
                <ImageZoom
                  image={{
                    src: '/assets/rom-logo-3.jpg',
                    className: 'img span-4',
                    style: { width: '100%'}
                  }}
                  zoomImage={{
                    src: '/assets/rom-logo-3.jpg',
                  }}
                />

              </div>
            </div>
            </div>

            <div id="dessys" className="bigger-bg">
              <div  className="mid-width case-study-body no-bottom">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Creating Consistency</p>
                    <p>012</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2>Building a robust <span className="header-serif">design system</span></h2>
                  <p className="project-study-inner">I created a comprehensive design system that goes beyond what is used in the final design. This helped me understand the intricacies of design systems and build screens faster.</p>
                </div>
              </div>
              <div className='bsc-2-grid'>
                <ImageZoom
                  image={{
                    src: '/assets/rom-ds-1.jpg',
                    className: 'img',
                    style: { width: '100%' }
                  }}
                  zoomImage={{
                    src: '/assets/rom-ds-1.jpg',
                  }}
                />
                <ImageZoom
                  image={{
                    src: '/assets/rom-ds-2.jpg',
                    className: 'img',
                    style: { width: '100%' }
                  }}
                  zoomImage={{
                    src: '/assets/rom-ds-2.jpg',
                  }}
                />

              </div>
            </div>


            <div id="VR" className="mid-width case-study-body case-study-body-section section-spacer">
              <div className="section-header-container about">
                <div className="section-header">
                  <p>Final Design</p>
                  <p>013</p>
                </div>
              </div>
              <div className="bsc-2-grid">
                <h2>Final VR <span className="header-serif">museum experience</span></h2>
                <p className="project-study-inner">Putting everything together, I created a VR museum scavenger hunt experience that truly immerses the user and gives them the ability to actually hold and touch museum artifacts.</p>
              </div>
              <div className='video-container'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/YJ_4bE43nAg?controls=0&amp;start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>

            <div id="reflection" className="bigger-bg">
              <div className="mid-width case-study-body">
                <div className="section-header-container about">
                  <div className="section-header">
                    <p>Lessons Learned</p>
                    <p>012</p>
                  </div>
                </div>
                <div className="bsc-2-grid">
                  <h2><span className="header-serif">Reflecting</span> on the project outcomes</h2>
                  <p className="project-study-inner">This project was intense. I worked on designs for web, mobile, AR and VR experiences. While there were lots of moving parts, I learned how to balance and design for multiple devices.</p>
                </div>
                <div className="info">
                  <div className="bsc-2-grid">
                    <div className="span-1">
                      <h6 className="top-number">01</h6>
                      <h4>Design systems should aid, not get in the way</h4>
                      <p className="project-study-inner-small">Designing for different devices put a strain on the design system I had built. It started feeling too rigid and I found myself changing designs to fit the system instead of the other way around. I realized that design systems should help designers, they shouldn't be held too rigidly that it impedes the user experience.</p>
                    </div>
                    <div className="span-1">
                      <h6 className="top-number">02</h6>
                      <h4>The same experience on different devices have their own unique challenges</h4>
                      <p className="project-study-inner-small">Creating a scavenger hunt on a web experience is very different from a scavenger hunt in VR. With VR, large amounts of text are not recommended. I had to be creative with how to deliver high experience quality while also following the best practices for that modality.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            


        </div></div>
      </div>
    </div>
  );
}

export default ROM;
